import React, { useState, useMemo, useEffect } from 'react';

import { AssetInterface, Transaction, TransactionActiveInput, TransactionMethod, TransactionType } from '../../@types/types';
import { FormattedAssetOutput } from '../../hooks/useFormattedAsset';
import { TransactionModal } from '../TransactionModal';
import { AssetDropdown } from './atoms/AssetDropdown';
import { useMarketAssetsContext } from '../../context/MarketContext';
import {
    CalculatedTransactionAmount,
    PurchaseSellInput,
    FullScreenTitle,
    BalanceFooter
} from './atoms';

import { useTransactAsset } from './hooks/useTransactAsset';

import {
    PurchaseSellContainer,
    BuySellContainer,
    BuySellButtonWrapper,
    InputActionsWrapper,
    InputActionsContainer,
    TotalAssetContainer,
    BuySellButtonContainer,
    BuySellButton,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    MarketLimitContainer,  MarketButton, LimitButton,
    LimitAskInput,
    LimitInputLabel,
    ErrorMessageWrapper
} from './styles';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { MOBILE_BREAKPOINT } from '../../styles/variables';
import { ErrorMessage } from '../common/ErrorMessage';

interface BuySellComponentProps {
    symbol?: string;
    formattedAsset?: FormattedAssetOutput;
    asQuickTrade?: boolean;
    hideOnMobile?: boolean;
    fullScreen?: boolean;
    initialTransactionType?: TransactionType;
    onCloseMobileModal?: () => void;
}

export const BuySellComponent: React.FC<BuySellComponentProps> = ({
    symbol,
    formattedAsset,
    asQuickTrade,
    fullScreen = false,
    hideOnMobile = false,
    initialTransactionType = TransactionType.BUY,
    onCloseMobileModal,
}) => {
    const [transactionAmount, setTransactionAmount] = useState<string>('');
    const [error, setError] = useState<string>();

    const [limitAsk, setLimitAsk] = useState<string>();

    const [transaction, setTransaction] = useState<Transaction | null>(null);
    const [activeInput, setActiveInput] = useState<TransactionActiveInput>(TransactionActiveInput.USD);
    const [activeTab, setActiveTab] = useState<TransactionType>(initialTransactionType);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [transactionAction, setTransactionAction] = useState<TransactionMethod>(TransactionMethod.MARKET);
    const [selectedAsset, setSelectedAsset] = useState<AssetInterface | null>(null);

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    
    const { windowWidth } = useWindowWidth();
    const { getAssetBySymbol } = useMarketAssetsContext();

    const onMobileUnmount = () => {
        setTransaction(null)
        setActiveInput(TransactionActiveInput.USD)
        setTransactionAmount('');
        onCloseMobileModal();
    };

    const asset = useMemo(() => {
        if (formattedAsset) return formattedAsset;

        if (selectedAsset) return selectedAsset;
    
        return {  ...getAssetBySymbol('BTC') };
    }, [formattedAsset, selectedAsset, getAssetBySymbol]);

    const transactionButtonText = useMemo(() => {
        if (transactionAction === TransactionMethod.LIMIT) {
            return 'Place order';
        }

        return `${activeTab === TransactionType.BUY ? 'Buy' : 'Sell'} ${asset?.symbol}`;
    }, [activeTab, asset, transactionAction]);

    const {
        error: transactionError,
        transaction: successfulTransaction,
        onStartTransaction
    } = useTransactAsset({
        symbol: asset?.symbol,
        limitAsk: Number(limitAsk) ?? getAssetBySymbol(asset?.symbol)?.currentPrice,
        transactionType: activeTab,
        transactionMethod: transactionAction,
        transactionActiveInput: activeInput,
        transactionAmount: Number(transactionAmount)
    });

    useEffect(() => {
        if (successfulTransaction && submitting) {
            setSubmitting(false);

            const purchaseSold = determineTransactionLabel();

            setTransaction({
                ...successfulTransaction,
                type: activeTab,
                symbol: asset?.symbol,
                method: transactionAction,
                assetsPurchasedSold: Number(purchaseSold),
                transactionTotal: Number(purchaseSold),
                purchaseSellAsk: Number(limitAsk)
            });

            setModalOpen(true);
            setTransactionAmount('');
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, transactionAmount, successfulTransaction, symbol, transactionAction]);

    useEffect(() => {
        if (transactionError) {
            setSubmitting(false);
            setError(transactionError);
        }
    }, [transactionError]);

    useEffect(() => {
        if (initialTransactionType) {
            setActiveTab(initialTransactionType);
        }
    }, [initialTransactionType]);

    // const handleChangeTransactionAction = (action: TransactionMethod) => {
    //     setTransactionAction(action);
    //     setActiveInput(TransactionActiveInput.USD);
    //     if (action === TransactionMethod.LIMIT) {
    //         const currentAssetPrice = getAssetBySymbol(asset?.symbol)?.currentPrice;
    //         setLimitAsk(currentAssetPrice.toString());
    //     }
    // };

    const determineTransactionLabel = () => {
        let purchaseSold = "0";
        switch(activeTab) {
            case TransactionType.BUY:
                if (activeInput === TransactionActiveInput.SYMBOL || transactionAction === TransactionMethod.LIMIT) {
                    return purchaseSold = transactionAmount;
                }
                if (transactionAction === TransactionMethod.MARKET) {
                    return purchaseSold = (Number(transactionAmount) / getAssetBySymbol(asset?.symbol)?.currentPrice).toFixed(3);
                }
                return purchaseSold;
            case TransactionType.SELL:
                if (activeInput === TransactionActiveInput.SYMBOL) {
                    return purchaseSold = transactionAmount;
                }
                return purchaseSold = (Number(transactionAmount) * getAssetBySymbol(asset?.symbol)?.currentPrice).toFixed(3);
            default: 
                return purchaseSold;
        }
    }

    const handleStartTransaction = () => {
        if (Number(transactionAmount) > 0) {
            setSubmitting(true);
            setError(null);
            onStartTransaction();
        }
    };

    if (hideOnMobile && windowWidth <= MOBILE_BREAKPOINT) return null;

    return (
        <PurchaseSellContainer $hideOnMobile={hideOnMobile} $fullScreen={fullScreen}>
            {!fullScreen ? (
                <BuySellContainer>
                    <BuySellButtonWrapper
                        $buy
                        $active={activeTab === TransactionType.BUY}
                        onClick={() => setActiveTab(TransactionType.BUY)}
                    >
                        <label>Buy</label>
                    </BuySellButtonWrapper>
                    <BuySellButtonWrapper
                        $active={activeTab === TransactionType.SELL}
                        onClick={() => setActiveTab(TransactionType.SELL)}
                    >
                        <label>Sell</label>
                    </BuySellButtonWrapper>
                </BuySellContainer>
            ) : (
                <FullScreenTitle
                    name={asset?.name}
                    transactionType={activeTab}
                    onClose={onMobileUnmount}
                />
            )}
            <InputActionsContainer>
                <InputActionsWrapper>                
                    <PurchaseSellInput
                        asset={asset}
                        transactionType={activeTab}
                        transactionAmount={transactionAmount}
                        transactionMethed={transactionAction}
                        transactionActiveInput={activeInput}
                        setTransactionAmount={(value) => setTransactionAmount(value)}
                        setTransactionInput={(input) => setActiveInput(input)}
                    />
                </InputActionsWrapper>
            </InputActionsContainer>
            {transactionAction === TransactionMethod.MARKET ? (
                <TotalAssetContainer>
                    <CalculatedTransactionAmount
                        symbol={asset.symbol}
                        transactionType={activeTab}
                        transactionActiveInput={activeInput}
                        transactionAmount={Number(transactionAmount)}
                    />
                </TotalAssetContainer>
            ) : (
                <TotalAssetContainer>
                    <LimitInputLabel>Limit price</LimitInputLabel>
                    <LimitAskInput
                        $buy={activeTab === TransactionType.BUY}
                        type="number"
                        placeholder="Symbol price"
                        defaultValue={getAssetBySymbol(asset?.symbol).currentPrice}
                        onChange={(e) => setLimitAsk(e.target.value)}
                    />
                </TotalAssetContainer>
            )}
            {asQuickTrade && <AssetDropdown onSelectedAsset={(asset) => setSelectedAsset(asset)} />}
            {/*<div>
                <MarketLimitContainer>
                    <MarketButton
                        $buy={activeTab === TransactionType.BUY}
                        $active={transactionAction === TransactionMethod.MARKET}
                        onClick={() => handleChangeTransactionAction(TransactionMethod.MARKET)}
                    >
                        Market
                    </MarketButton>
                    <LimitButton
                        $buy={activeTab === TransactionType.BUY}
                        $active={transactionAction === TransactionMethod.LIMIT}
                        onClick={() => handleChangeTransactionAction(TransactionMethod.LIMIT)}
                    >
                        Limit
                    </LimitButton>
                </MarketLimitContainer>
            </div>*/}
            <ErrorMessageWrapper>
                <ErrorMessage text={error} fontSize={1} />
            </ErrorMessageWrapper>
            <BuySellButtonContainer>
                <BuySellButton
                    $buy={activeTab === TransactionType.BUY}
                    disabled={submitting}
                    onClick={handleStartTransaction}
                >
                    <span>{transactionButtonText}</span>
                </BuySellButton>
            </BuySellButtonContainer>
            <BalanceFooter
                activeTab={activeTab}
                activeInput={activeInput}
                symbol={asset?.symbol}
            />
            <TransactionModal
                open={modalOpen}
                transaction={transaction}
                setOpen={() => setModalOpen(false)}
            />
        </PurchaseSellContainer>
    )
}