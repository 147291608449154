import { css } from 'styled-components';

export const flexColumnCenter = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const flexColumnStart = css`
    display: flex;
    flex-direction: column;
    align-items: start;
`;

export const flexBetweenCentered = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export type MobileBreakpoint = 'mobile' | 'tablet';

export const buildBreakpoint = (type: MobileBreakpoint) => {
    return css`@media screen and (max-width: ${(props) => props.theme.breakpoints[type]})`
};
