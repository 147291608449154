import { useMemo } from 'react';

const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
];

const lookupExpression = /\.0+$|(\.[0-9]*[1-9])0+$/;

type CurrencyType = 'suffixed' | 'currency';

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 20
});

export const useFormattedCurrency = (value, type: CurrencyType = 'suffixed', decimals = 1) => {
    return useMemo(() => {
        if (type === 'currency') {
            return currencyFormatter.format(value);
        }
        const item = lookup.slice().reverse().find((item) => value >= item.value);
        return item ? (value / item.value).toFixed(decimals).replace(lookupExpression, "$1") + item.symbol : "0";
    }, [value, type, decimals]);
};
