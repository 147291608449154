import React, { ReactElement } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Icon } from '../../components/common/icons';
import { SiteLogo } from '../../components/SiteLogo';
import { buildBreakpoint } from '../../styles/common';

const SidebarContainer = styled.nav`
    width: 240px;
    position: fixed;
    top: 0;
    min-height: 100vh;
    border-right: 1px solid ${(props) => props.theme.colors.border};
    background-color: ${(props) => props.theme.body};

    ${buildBreakpoint('tablet')} {
        display: none;
    };
`;

const SidebarInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
`;

const LinksContainer = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const LinkItemContainer = styled(Link)`
    cursor: pointer;
    font-size: 1rem;
    text-decoration: none;
    color: inherit;
    transition: 0.25s;
    margin-bottom: 1rem;
`;

const activeStyles = css`
    border: none;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primaryFaded};
`;

const LinkItemWrapper = styled.div<{ $active?: boolean }>`
    width: 170px;
    text-align: center;
    padding: 1rem;
    border-radius: 25px;
    display: flex;
    align-items: center;
    transition: 0.25s;

    span {
        font-weight: 500;
    }

    svg {
        fill: ${(props) => props.$active ? props.theme.colors.primary : props.theme.text};
    }

    :hover {
        background-color: ${(props) => props.theme.colors.primaryFaded};
        color: ${(props) => props.theme.colors.primary};
        svg {
            fill: ${(props) => props.theme.colors.primary};
        }
    }
    ${(props) => props.$active && activeStyles};
`;

const LinkIconWrapper = styled.div<{ $active?: boolean }>`
    margin-right: 1rem;
    svg {
        transition: 0.25s;
        fill: ${(props) => props.$active && props.theme.colors.primary};
    }
`;

interface SidebarLinkProps {
    to: string;
    label: string;
    icon: React.ReactElement;
};

const links: SidebarLinkProps[] = [
    {
        to: '/home',
        label: 'Home',
        icon: <Icon variant="home" />
    },
    {
        to: '/assets',
        label: 'Assets',
        icon: <Icon variant="bitcoin" />
    },
    {
        to: '/explore',
        label: 'Explore',
        icon: <Icon variant="explore" />
    },
    {
        to: '/leaderboard',
        label: 'Leaderboard',
        icon: <Icon variant="trophy" />
    },
    // {
    //     to: '/learn',
    //     label: 'Learn & Earn',
    //     icon: <Icon variant="learn" />
    // },
    // {
    //     to: '/all-transactions',
    //     label: 'Transactions',
    //     icon: <Icon variant="receipt" />
    // },
]

const SidebarLinkItem = ({ to, label, icon }: SidebarLinkProps): ReactElement => {
    const path = window.location.pathname;

    return (
        <LinkItemContainer to={to}>
            <LinkItemWrapper $active={path === to}>
                <LinkIconWrapper>
                    {icon}
                </LinkIconWrapper>
                <span>{label}</span>
            </LinkItemWrapper>
        </LinkItemContainer>
    );
};

export const Sidebar: React.FC = () => {
    return (
        <SidebarContainer>
            <SidebarInnerContainer>
                <SiteLogo />
                <LinksContainer>
                    {links.map((item) => (
                        <SidebarLinkItem key={item.to} {...item} />
                    ))}
                </LinksContainer>
            </SidebarInnerContainer>
        </SidebarContainer>
    );
};
