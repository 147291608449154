import { initializeApp } from "firebase/app";
import {
    User,
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
    sendEmailVerification,
    updateEmail,
    updatePassword,
    reauthenticateWithCredential,
    sendPasswordResetEmail,
    deleteUser
} from "firebase/auth";
import {
    getDatabase,
    ref,
    set,
    get,
    remove,
    onValue
} from "firebase/database";
import {
    getStorage,
    ref as storageRef,
    uploadBytesResumable,
    getDownloadURL
} from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDF1K2_8kJKGxxN1fiQa3aIlQWpdhRROGE",
    authDomain: "bitsim-7f1f8.firebaseapp.com",
    projectId: "bitsim-7f1f8",
    databaseURL: "https://bitsim-7f1f8-default-rtdb.firebaseio.com",
    storageBucket: "bitsim-7f1f8.appspot.com",
    messagingSenderId: "727965938999",
    appId: "1:727965938999:web:dceb21f10b769c52b356b2"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getDatabase(app);
export const googleProvider = new GoogleAuthProvider();
export const storage = getStorage(app);

export interface UserInterface extends User { };

export {
    signInWithPopup,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    updateEmail,
    updatePassword,
    reauthenticateWithCredential,
    sendPasswordResetEmail,
    deleteUser
};

export {
    ref,
    set,
    get,
    remove,
    onValue,
    storageRef,
    uploadBytesResumable,
    getDownloadURL
}