import React, { useMemo, useState } from 'react';

import styled from 'styled-components';
import { AssetInterface } from '../../../@types/types';
import { buildBreakpoint } from '../../../styles/common';
import { BuySellComponent } from '../../BuySell';

import { ThemedModal } from '../../Modals/ThemedModal';

const ButtonWrapper = styled.div`
    margin-right: 4rem;

    ${buildBreakpoint('tablet')} {
        margin-right: 0rem;
        display: none;
    };
`;

const TradeButton = styled.button`
    font-size: 1rem;
    font-family: inherit;
    padding: 0.4rem 3rem;
    border-radius: 20px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s;
    border: none;
    color: white;
    background-color: ${(props) => props.theme.colors.primary};
`;

const TableRowButton = styled.button`
    font-size: 1rem;
    font-family: inherit;
    font-weight: 500;
    padding: 0.2rem 2rem;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.2s;
    border: none;
    color: white;
    background-color: ${(props) => props.theme.colors.primary};

    :hover {
        background-color: ${(props) => props.theme.colors.primaryLight};
    }
`;

interface QuickTradeButtonProps {
    asset?: AssetInterface;
    asTableRowItem?: boolean;
}

export const QuickTradeButton: React.FC<QuickTradeButtonProps> = ({ asset, asTableRowItem }) => {
    const [open, setOpen] = useState<boolean>(false);

    const renderButton = useMemo(() => {
        if (asTableRowItem) {
            return (
                <TableRowButton onClick={(e) => {
                    e.stopPropagation()
                    setOpen(!open);
                }}>
                    Buy
                </TableRowButton>
            )
        }
        return (
            <ButtonWrapper>
                <TradeButton onClick={() => setOpen(!open)}>
                    Quick trade
                </TradeButton>
            </ButtonWrapper>
        )
    }, [asTableRowItem, open]);

    return (
        <>        
            {renderButton}
            <ThemedModal
                large
                open={open}
                handleClose={() => setOpen(false)}
            >
                <BuySellComponent asQuickTrade={!asset} formattedAsset={asset} />
            </ThemedModal>
        </>
    );
};
