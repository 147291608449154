
import { UserContextProvider } from './UserContext';
import { ThemeContextProvider } from './ThemeContext';
import { MarketContextProvider } from './MarketContext';
import { NavContextProvider } from './NavContext';
import { FirebaseContextProvider } from './FirebaseContext';
import { ProviderProps } from '../@types/types';

export const GlobalContextProvider: React.FC<ProviderProps> = ({ children }) => {
    return (
        <>
            <UserContextProvider>
                <ThemeContextProvider>
                    <MarketContextProvider>
                        <NavContextProvider>
                            <FirebaseContextProvider>
                                {children}
                            </FirebaseContextProvider>
                        </NavContextProvider>
                    </MarketContextProvider>
                </ThemeContextProvider>
            </UserContextProvider>
        </>
    );
};
