import { createContext, useState, useEffect, useMemo, useContext } from 'react';

import { AssetInterface, ProviderProps, RequestError, TradeType, User, UserAssetInterface } from '../@types/types';
import { useUserContext } from './UserContext';
import { useRequest } from '../hooks/useRequest';

interface OpenLimitOrderInput {
    symbol: string;
    limitAsk: number;
    purchaseAsk?: number;
    sellAsk?: number;
    type: TradeType;
}

interface MarketContextInterface {
    assets: AssetInterface[];
    userAssets: UserAssetInterface[];
    biggestMovers: AssetInterface[];
    initialized: boolean;

    getAssetBySymbol?: (symbol: string) => AssetInterface;
    handlePurchaseAsset: (pair: string, purchaseAmount: number) => Promise<any>;
    handleSellAsset: (pair: string, sellAmount: number) => Promise<any>;
    handleOpenLimitOrder: (order: OpenLimitOrderInput) => Promise<any>;
    handleCancelOpenOrder: (id: string) => Promise<any>;
}

export const MarketContext = createContext(undefined as unknown as MarketContextInterface);

export const MarketContextProvider: React.FC<ProviderProps> = ({ children }) => {
    const [assets, setAssets] = useState<AssetInterface[]>([]);
    const [initialized, setInitialized] = useState<boolean>(false);

    const { user, setUserData } = useUserContext();

    const { request, authenticatedRequest } = useRequest();

    useEffect(() => {
        fetchAssets();
        const tickInterval = setInterval(() => {
            fetchAssets();
        }, 5000);

        return () => clearInterval(tickInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAssets = () => {
        request<AssetInterface[]>({ endpoint: '/all-assets' })
        .then((data) => {
            const assets = data.map((asset) => {
                const url = asset.symbol.toLocaleLowerCase() + 'usd';
                const name = asset.name === 'XRP' ? 'Ripple' : asset.name;
                return { ...asset, name, url }
            });
            setAssets(assets);

            if (!initialized) {
                setInitialized(true);
            }
        });
    };

    const getAssetBySymbol = (symbol: string) =>  assets.find((asset) => asset.symbol === symbol);

    const biggestMovers = useMemo(() => {
        const movers = [...assets];
        return movers.sort((a, b) => Math.abs(a.dayChangePercentage) < Math.abs(b.dayChangePercentage) ? 1 : -1);
    }, [assets]);

    const userAssets: UserAssetInterface[] = useMemo(() => {
        if (!user?.assets) return [];

        return Object.entries(user.assets)
        .map(([symbol, { shares }]) => {
            const userAsset = assets.find((asset) => asset.symbol === symbol);
            const averagedPosition = user?.openPositions[symbol]?.sharePrices?.reduce((a, b) => a + b, 0);
            const gaugedAverage = averagedPosition / user?.openPositions[symbol]?.sharePrices?.length;
            const average = ((userAsset?.currentPrice - gaugedAverage) / gaugedAverage) * 100;

            return { ...userAsset, shares, average };
        }).filter(({ shares }) => shares > 0);
    }, [user, assets]);

    const handlePurchaseAsset = async (symbol: string, assetAmount: number) => {
        return authenticatedRequest<User | RequestError>({ endpoint: '/purchase-asset', user, postData: {
            symbol,
            walletAddress: user.walletAddress,
            assetAmount
        }})
        .then((data) => {
            setUserData((prev) => ({
                ...prev,
                ...data
            }))
            return data;
        })
        .catch((error) => error)
    };

    const handleSellAsset = async (symbol: string, assetAmount: number) => {
        return authenticatedRequest<User | RequestError>({ endpoint: '/sell-asset', user, postData: {
            symbol,
            assetAmount,
            walletAddress: user.walletAddress,
        }})
        .then((data) => {
            setUserData((prev) => ({
                ...prev,
                ...data
            }));

            return data;
        })
        .catch((error) => error);
    };

    const handleOpenLimitOrder = ({ symbol, limitAsk, purchaseAsk, sellAsk, type }: OpenLimitOrderInput) => {
        return authenticatedRequest<User | RequestError>({ endpoint: '/open-limit-position', user, postData: {
            type,
            symbol,
            limitAsk,
            purchaseAsk,
            sellAsk,
            walletAddress: user.walletAddress
        }})
        .then((data) => {
            setUserData((prev) => ({
                ...prev,
                ...data
            }));
            return data;
        })
        .catch((error) => error)
    };

    const handleCancelOpenOrder = (positionId: string) => {
        return authenticatedRequest<User | RequestError>({ endpoint: '/cancel-limit-position', user, postData: {
            positionId,
            walletAddress: user.walletAddress
        }})
        .then((data) => {
            setUserData((prev) => ({
                ...prev,
                ...data
            }));
            return data;
        })
        .catch((error) => error);
    };

    return (
        <MarketContext.Provider
            value={{
                assets,
                userAssets,
                biggestMovers,
                initialized,

                getAssetBySymbol,
                handlePurchaseAsset,
                handleSellAsset,
                handleOpenLimitOrder,
                handleCancelOpenOrder
            }}>
            {children}
        </MarketContext.Provider>
    );
};

export const useMarketAssetsContext = () => {
    const context = useContext(MarketContext);
    return context;
}
