import React from 'react';

import styled from 'styled-components';

import { ProviderProps } from '../../@types/types';
import { SkeletonLoader, SkeletonVariant } from '../SkeletonLoader';

const MultiSkeletonContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const MultiSkeletonWrapper = styled.div<{ $overrideStyles?: any }>`
    margin: 1rem;
    ${(props) => props.$overrideStyles};
`;

const LoadableElementContainer = styled.div<{ $overrideStyles?: any }>`
    ${(props) => props.$overrideStyles};
`;

interface LoadableElementProps extends ProviderProps {
    loading: boolean;
    width?: number;
    height?: number;
    type?: SkeletonVariant;
    numLoaders?: number;
    loaderContainerStyles?: any;
}

export const LoadableElement: React.FC<LoadableElementProps> = ({
    children,
    loading,
    width,
    height = 20,
    type = 'text',
    numLoaders,
    loaderContainerStyles
}) => {
    const makeRange = (range: number) => Array.from(Array(numLoaders).keys())

    if (loading) {
        if (numLoaders) {
            return (
                <MultiSkeletonContainer>
                    {makeRange(numLoaders).map((loader) => (
                        <MultiSkeletonWrapper key={loader} $overrideStyles={loaderContainerStyles}>
                            <SkeletonLoader width={width} height={height} variant={type} />
                        </MultiSkeletonWrapper>
                    ))}
                </MultiSkeletonContainer>
            )
        }
        return (
            <LoadableElementContainer $overrideStyles={loaderContainerStyles}>
                <SkeletonLoader width={width} height={height} variant={type} />
            </LoadableElementContainer>
        );
    };

    return <>{children}</>
};
