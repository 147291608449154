import React from 'react';

import styled, { css } from 'styled-components';
import { buildBreakpoint } from '../../../styles/common';

import { Icon, IconVariant } from '../../common/icons';

type InputWidth = 'large' | 'full-width';

const WIDE_INPUT_SIZE = '300px';
const FULL_WIDTH = '100%';

const InputWrapper = styled.div`
    position: relative;
`;

const underlineStyles = css`
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const outlinedStyles = css`
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.border};
`;

interface StyledInputProps {
    $size?: InputWidth;
    $enableGutters?: boolean;
    $hasIcon?: boolean;
    $outlined?: boolean;
    $transparent?: boolean;
}

const StyledInput = styled.input<StyledInputProps>`
    font-size: 1rem;
    padding: 0.5rem;
    padding-left: ${(props) => props.$hasIcon || props.$outlined ? 2 : 0.5}rem;
    background: none;
    outline: none;
    font-family: inherit;
    border: none;
    border-radius: 0px;
    background-color: ${(props) => props.$transparent ? 'transparent' : props.theme.colors.surface} !important;
    margin-inline: ${(props) => props.$enableGutters && '1rem'};
    width: ${(props) => props.$size === 'large' ? WIDE_INPUT_SIZE : FULL_WIDTH};
    color: ${(props) => props.theme.text};

    ${(props) => props.$outlined ? outlinedStyles : underlineStyles};

    ${buildBreakpoint('tablet')} {
        width: 100%;
        max-width: 250px;
    };
`;

const InputLabel = styled.span`
    font-size: 0.7rem;
    opacity: 0.8;
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 10px;

    svg {
        fill: ${(props) => props.theme.colors.border};
    }
`;

const EndIconWrapper = styled.div<{ $iconColor?: string; }>`
    position: absolute;
    top: 8px;
    right: 12px;

    svg {
        fill: ${(props) => props.theme.colors[props.$iconColor ?? 'border']};
    }
`;

interface InputProps {
    placeholder?: string;
    onChange?: (e: any) => void;
    size?: InputWidth;
    enableGutters?: boolean;
    label?: string;
    outlined?: boolean;
    iconColor?: string;
    transparent?: boolean;
    icon?: keyof typeof IconVariant;
    endIcon?: keyof typeof IconVariant;
    [x: string]: any;
}

export const InputField: React.FC<InputProps> = ({
    placeholder,
    onChange,
    size = 'large',
    enableGutters = false,
    label,
    icon,
    endIcon,
    iconColor,
    outlined,
    transparent,
    ...rest
}) => {
    return (
        <div>
            <div>
                {label ? <InputLabel>{label}</InputLabel> : null}
            </div>
            <InputWrapper>
                {icon ? (
                    <IconWrapper>
                        <Icon variant={icon} />
                    </IconWrapper>
                ) : null}
                <StyledInput
                    autoComplete='new-password'
                    $size={size}
                    $transparent={transparent}
                    $hasIcon={!!icon}
                    $enableGutters={enableGutters}
                    placeholder={placeholder}
                    $outlined={outlined}
                    onChange={onChange}
                    {...rest}
                />
                {endIcon ? (
                    <EndIconWrapper $iconColor={iconColor}>
                        <Icon variant={endIcon} />
                    </EndIconWrapper>
                ) : null}
            </InputWrapper>
        </div>
    );
};
