import React from 'react';

import styled from 'styled-components';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { TransactionType } from '../../../@types/types';

const TitleContainer = styled.div`
    margin: 2rem;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;

    span {
        font-weight: 500;
        font-size: 1.2rem;
    }
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    right: -10px;
    top: -10px;

    svg {
        fill: ${(props) => props.theme.text};
    }
`;

interface FullScreenTitleProps {
    name: string;
    transactionType: TransactionType;
    onClose: () => void;
}

export const FullScreenTitle: React.FC<FullScreenTitleProps> = ({
    name,
    transactionType,
    onClose
}) => {
    return (
        <TitleContainer>
            <span>{transactionType === TransactionType.BUY ? 'Buy' : 'Sell'} {name}</span>
            <CloseButtonContainer>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </CloseButtonContainer>
        </TitleContainer>
    );
};
