import React, { useMemo } from 'react';

import styled from 'styled-components';

import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SellIcon from '@mui/icons-material/Sell';
import AddIcon from '@mui/icons-material/Add';
import VerifiedIcon from '@mui/icons-material/Verified';
import PersonIcon from '@mui/icons-material/Person';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import CancelIcon from '@mui/icons-material/Cancel';
import LoopIcon from '@mui/icons-material/Loop';
import CreateIcon from '@mui/icons-material/Create';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import SearchIcon from '@mui/icons-material/Search';
import ExploreIcon from '@mui/icons-material/Explore';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { TradeIcon } from './TradeIcon';
import { BitcoinIcon } from './BitcoinIcon';
import { HomeIcon } from './HomeIcon';
import { LightIcon } from './LightIcon';
import { DarkIcon } from './DarkIcon';
import { MoreIcon } from './MoreIcon'; 
import { SignatureIcon } from './SignatureIcon';

const DEFAULT_ICON_WIDTH = 24;
const DEFAULT_ICON_HEIGHT = 24;

export enum IconVariant {
    trade = 'trade',
    bitcoin = 'bitcoin',
    home = 'home',
    light = 'light',
    dark = 'dark',
    more = 'more',
    watchlist = 'watchlist',
    learn = 'learn',
    receipt = 'receipt',
    personOutline = 'personOutline',
    lock = 'lock',
    nameTag = 'nameTag',
    signature = 'signature',
    mail = 'mail',
    sell = 'sell',
    add = 'add',
    check = 'check',
    person = 'person',
    settings = 'settings',
    logout = 'logout',
    notification = 'notification',
    moon = 'moon',
    trophy = 'trophy',
    camera = 'camera',
    cancel = 'cancel',
    loading = 'loading',
    edit = 'edit',
    save = 'save',
    swap = 'swap',
    swapHorizontal = 'swapHorizontal',
    upArrow = 'upArrow',
    cycleArrows = 'cycleArrows',
    search = 'search',
    explore = 'explore',
    menu = 'menu',
    close = 'close'
}

interface StyledIconProps {
    width?: number;
    height?: number;
}

interface IconProps extends StyledIconProps {
    variant: keyof typeof IconVariant;
}

const StyledIconWrapper = styled.div<StyledIconProps>`
    display: flex;
    svg {
        fill: ${(props) => props.theme.text};
        width: ${(props) => props.width ?? DEFAULT_ICON_WIDTH}px;
        height: ${(props) => props.height ?? DEFAULT_ICON_HEIGHT}px;
    }
`;

export const Icon: React.FC<IconProps> = ({ variant, ...rest }) => {
    const renderIcon = useMemo(() => {
        switch (variant) {
            case "trade":
                return <TradeIcon />;
            case "bitcoin":
                return <BitcoinIcon />;
            case "home":
                return <HomeIcon />;
            case "light":
                return <LightIcon />;
            case "dark":
                return <DarkIcon />;
            case "more":
                return <MoreIcon />;
            case "signature":
                return <SignatureIcon />;
            case "watchlist":
                return <StarIcon fontSize='medium' />;
            case "learn":
                return <SchoolIcon fontSize='medium' />;
            case "receipt":
                return <ReceiptIcon fontSize='medium' />;
            case "personOutline":
                return <PersonOutlineIcon fontSize='medium' />;
            case "lock":
                return <LockOpenIcon fontSize='medium' />;
            case "nameTag":
                return <AssignmentIndIcon fontSize='medium' />;
            case "mail":
                return <MailOutlineIcon fontSize='medium' />;
            case "sell":
                return <SellIcon fontSize='medium' />;
            case "add":
                return <AddIcon fontSize='medium' />;
            case "check":
                return <VerifiedIcon fontSize='medium' />;
            case "person":
                return <PersonIcon fontSize='medium' />;
            case "settings":
                return <Settings fontSize='medium' />;
            case "logout":
                return <Logout fontSize='medium' />;
            case "notification":
                return <NotificationsActiveIcon fontSize='medium' />;
            case "moon":
                return <NightsStayIcon fontSize='medium' />;
            case "trophy":
                return <EmojiEventsIcon fontSize='medium' />;
            case "camera":
                return <PhotoCamera fontSize='large' />;
            case "cancel":
                return <CancelIcon fontSize='medium' />;
            case "loading":
                return <LoopIcon fontSize='small' />;
            case "edit":
                return <CreateIcon fontSize='medium' />;
            case "save":
                return <SaveAltIcon fontSize='medium' />;
            case "swap":
                return <SwapVerticalCircleIcon fontSize='medium' />;
            case "swapHorizontal":
                return <SwapHorizontalCircleIcon fontSize='medium' />;
            case "upArrow":
                return <ArrowCircleUpIcon fontSize='medium' />;
            case "cycleArrows":
                return <ChangeCircleIcon fontSize='medium' />;
            case "search":
                return <SearchIcon fontSize='medium' />;
            case "explore":
                return <ExploreIcon fontSize='medium' />;
            case "menu":
                return <MenuIcon fontSize='medium' />;
            case "close":
                return <CloseIcon fontSize='medium' />;
        }
    }, [variant]);

    return (
        <StyledIconWrapper {...rest}>
            {renderIcon}
        </StyledIconWrapper>
    );
};
