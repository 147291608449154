import React from 'react';

import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { ProviderProps } from '../../@types/types';
import { Sidebar } from '../Sidebar';
import { Navbar } from '../Navbar';
import { MobileFooter } from '../MobileFooter';
import { buildBreakpoint } from '../../styles/common';
import { SIDEBAR_WIDTH_IN_PX } from '../../styles/variables';

const ContentContainer = styled.main`
    display: flex;

    ${buildBreakpoint('tablet')} {
        margin-bottom: 8rem;
    };
`;

const MainContent = styled.div`
    margin-left: ${SIDEBAR_WIDTH_IN_PX}px;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;

    ${buildBreakpoint('tablet')} {
        margin-left: 0;
    };
`;

interface AppLayoutProps extends ProviderProps {
    pageTitle?: string;
}

export const AuthLayout: React.FC<AppLayoutProps> = ({ children }) => {
    return (
        <>
            <Helmet>
                {/* <meta name="robots" content="noindex, nofollow" /> */}
            </Helmet>
            <ContentContainer>
                <Sidebar />
                <MainContent>
                    <Navbar />
                    {children}
                    <MobileFooter />
                </MainContent>
            </ContentContainer>
        </>
    );
};
