import React, { useEffect, useRef, useState, useMemo } from 'react';

import styled from 'styled-components';
import { Tooltip, IconButton } from '@mui/material';

import { Icon } from '../../common/icons';
import { TransactionActiveInput, TransactionMethod, TransactionType } from '../../../@types/types';
import { FormattedAssetOutput } from '../../../hooks/useFormattedAsset';
import { useUserContext } from '../../../context/UserContext';
import { useMarketAssetsContext } from '../../../context/MarketContext';
import { buildBreakpoint } from '../../../styles/common';

const FlexibleInputContainer = styled.div<{ $scale: number }>`
    display: flex;
    justify-content: center;
    flex: 1 1 0%;
    transform: scale(${(props) => props.$scale});
    transition: 0.2s;
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    align-items: baseline;
`;

const InputPrefix = styled.span<{ $hasValue: boolean; $buy: boolean; }>`
    line-height: 1.5;
    align-self: flex-start;
    margin-top: 0.6rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: ${(props) => props.$hasValue ? props.theme.colors[props.$buy ? 'primary' : 'secondary'] : props.theme.colors.gray};

    ${buildBreakpoint('mobile')} {
        line-height: 2.5;
        margin-right: -1rem;
    };
`;

const FlexibleInputField = styled.input<{ $width?: number }>`
    background: transparent;
    border: none;
    resize: horizontal;
    font-size: 60px;
    width: ${(props) => props.$width ?? 10}ch;
    font-family: inherit;
    outline: none;
    color: ${(props) => props.theme.text};
`;

const MaxButtonContainer = styled.div`
    position: absolute;
    top: 0;
    right: 15px;
`;

const UseAssetOrDollarAmount = styled.div`
    position: absolute;
    top: 0;
    left: 15px;
    z-index: 100;
`;

const MaxButton = styled(IconButton)`
    &&& {
        svg {
            fill: ${(props) => props.theme.colors.gray};
        }
    }
`;

interface PurchaseSellInputProps {
    asset: FormattedAssetOutput;
    transactionAmount: string;
    transactionType: TransactionType;
    transactionMethed: TransactionMethod;
    transactionActiveInput?: TransactionActiveInput;
    setTransactionAmount: (value: string) => void;
    setTransactionInput?: (value: TransactionActiveInput) => void;
}

export const PurchaseSellInput: React.FC<PurchaseSellInputProps> = ({
    asset,
    transactionAmount,
    transactionType,
    transactionMethed,
    transactionActiveInput = TransactionActiveInput.USD,
    setTransactionAmount,
    setTransactionInput
}) => {
    const [inputScale, setInputScale] = useState<number>(1);

    const inputRef = useRef<HTMLInputElement>(null);

    const { user } = useUserContext();
    const { getAssetBySymbol } = useMarketAssetsContext();

    const handleSetMax = () => {
        switch (transactionType) {
            case TransactionType.BUY:
                if (transactionActiveInput === TransactionActiveInput.USD) {
                    return setTransactionAmount(user.balance.toFixed(2));
                }
                return setTransactionAmount((user?.assets[asset.symbol]?.shares ?? 0).toString());
            case TransactionType.SELL:
                if (transactionActiveInput === TransactionActiveInput.SYMBOL) {
                    const totalAssetValue = (user?.assets[asset.symbol]?.shares ?? 0) * getAssetBySymbol(asset.symbol).currentPrice;
                    return setTransactionAmount(totalAssetValue.toString());
                }
                const totalAssetsHeld = (user?.assets[asset.symbol]?.shares ?? 0).toString();
                return setTransactionAmount(totalAssetsHeld);
        }
    };

    const handleChangeInputType = () => {
        const newInput = TransactionActiveInput[transactionActiveInput === TransactionActiveInput.USD ? 'SYMBOL' : 'USD'];
        setTransactionInput(newInput);
    };

    useEffect(() => {
        if (!inputRef?.current) return;

        if (inputRef.current.value.length <= 4) {
            return setInputScale(1);
        }

        // Don't judge me..
        const nextValue = inputRef.current.value.length === 5 ? 0.9 :
                            inputRef.current.value.length === 6 ? 0.8 :
                            inputRef.current.value.length === 7 ? 0.7 :
                            inputRef.current.value.length === 8 ? 0.6 :
                            inputRef.current.value.length === 9 ? 0.5 :
                            inputRef.current.value.length === 10 ? 0.4 :
                            0.3;

        const newValue = Math.min(Math.max(nextValue, 0.3), 0.9);

        setInputScale(newValue);

    }, [inputScale, transactionAmount]);

    const inputLabel = useMemo(() => {
        if (transactionType === TransactionType.BUY) {
            return {
                prefix: transactionActiveInput === TransactionActiveInput.USD ? '$' : asset?.symbol,
                tooltip: transactionActiveInput === TransactionActiveInput.USD ? asset?.symbol : 'USD'
            }
        }

        return {
            prefix: transactionActiveInput === TransactionActiveInput.USD ? asset?.symbol : '$',
            tooltip: transactionActiveInput === TransactionActiveInput.USD ? asset?.symbol : 'USD'
        };
    }, [asset?.symbol, transactionType, transactionActiveInput])

    const handleChange = (e) => {
        setTransactionAmount(e.target.value);
    };

    return (
        <>   
            <UseAssetOrDollarAmount>
                <Tooltip title="Max">
                    <MaxButton onClick={handleSetMax}>
                        <Icon variant="upArrow" />
                    </MaxButton>
                </Tooltip>
            </UseAssetOrDollarAmount>
            <FlexibleInputContainer $scale={inputScale}>
                <InputContainer>
                    <InputPrefix
                        $buy={transactionType === TransactionType.BUY}
                        $hasValue={transactionAmount.length > 0}
                    >
                        {inputLabel.prefix}
                    </InputPrefix>
                    <FlexibleInputField
                        $width={transactionAmount.length > 1 ? transactionAmount.length - 0.1 : 1}
                        placeholder="0"
                        inputMode="decimal"
                        type="number"
                        ref={inputRef}
                        value={transactionAmount}
                        maxLength={10}
                        onChange={handleChange}
                    />
                </InputContainer>
            </FlexibleInputContainer>
            <MaxButtonContainer>
                {transactionMethed === TransactionMethod.MARKET ? (
                    <Tooltip title={inputLabel.tooltip}>
                        <MaxButton onClick={handleChangeInputType}>
                            <Icon variant="cycleArrows" />
                        </MaxButton>
                    </Tooltip>
                ) : null}
            </MaxButtonContainer>
        </>
    );
};