import { useState, useMemo } from "react";

import { Transaction, TransactionActiveInput, TransactionMethod, TransactionType } from "../../../@types/types";
import { useMarketAssetsContext } from "../../../context/MarketContext";

interface UseTransactAssetInput {
    symbol: string;
    limitAsk: number;
    transactionAmount: number;
    transactionType: TransactionType;
    transactionMethod: TransactionMethod;
    transactionActiveInput: TransactionActiveInput;
}

interface UseTransactAssetOutput {
    error: string;
    transaction: Transaction;
    onStartTransaction: () => void;
}

export const useTransactAsset = ({
    symbol,
    transactionType,
    transactionMethod,
    transactionAmount,
    limitAsk,
    transactionActiveInput
}: UseTransactAssetInput): UseTransactAssetOutput => {
    const [error, setError] = useState<string>('');
    const [transaction, setTransaction] = useState<Transaction>();

    const {
        handlePurchaseAsset,
        handleSellAsset,
        handleOpenLimitOrder,
        getAssetBySymbol
    } = useMarketAssetsContext();

    const onStartTransaction = () => {
        if (transactionAmount <= 0) return;
        setError('');
        switch (transactionType) {
            case TransactionType.BUY:
                if (transactionMethod === 'MARKET') {
                    const adjustedTransactionAmount = transactionActiveInput === TransactionActiveInput.SYMBOL ?
                        transactionAmount :
                        transactionAmount / getAssetBySymbol(symbol)?.currentPrice;

                    return handlePurchaseAsset(symbol, adjustedTransactionAmount)
                        .then((data) => {
                            if (data.error) {
                                return setError(data.error);
                            }
                            setTransaction(data);
                        })
                        .catch((e) => {
                            return setError(e);
                        });
                } else {
                    return handleOpenLimitOrder({
                            symbol,
                            limitAsk: limitAsk ?? getAssetBySymbol(symbol)?.currentPrice,
                            type: 'PURCHASE',
                            purchaseAsk: transactionAmount,
                        })
                        .then((data) => {
                            if (data.error) {
                                return setError(data.error);
                            }
                
                            setTransaction(data);
                        })
                }
            case TransactionType.SELL:
                if (transactionMethod === 'MARKET') {
                    const adjustedTransactionAmount = transactionActiveInput === TransactionActiveInput.USD ?
                    transactionAmount :
                    transactionAmount / getAssetBySymbol(symbol)?.currentPrice;
    
                    return handleSellAsset(symbol, adjustedTransactionAmount)
                        .then((data) => {
                            if (data.error) {
                                return setError(data.error);
                            }
                            setTransaction(data);
                        });
                } else {
                    return handleOpenLimitOrder({
                            symbol,
                            limitAsk,
                            type: 'SELL',
                            sellAsk: transactionAmount,
                        })
                        .then((data) => {
                            if (data.error) {
                                return setError(data.error);
                            }
                
                            setTransaction(data);
                        });
                }
        }
    };

    const outTransaction = useMemo(() => ({
        transaction,
        error,
    }), [transaction, error]);

    return { ...outTransaction, onStartTransaction };
}