import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Icon, IconVariant } from '../../components/common/icons';
import { buildBreakpoint } from '../../styles/common';
import { MobileTradeComponent } from './MobileTradeComponent';

const MobileFooterContainer = styled.div`
    display: none;

    ${buildBreakpoint('tablet')} {
        display: block;
        width: 100%;
        position: fixed;
        bottom: 0px;
        height: 100px;
        background-color: ${(props) => props.theme.colors.surface};
    };
`;

const MobileFooterInnerContainer = styled.div`
    padding-inline: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`;

const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.3rem;
`;

const MainLinksContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    width: -webkit-fill-available;
    padding-bottom: 1rem;
    padding-inline: 0.5rem;
`;

const homeLinkStyles = css`
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.primary};
`;

const StyledLink = styled(Link)<{ $active?: boolean; $homeLink?: boolean; }>`
    text-decoration: none;
    font-weight: 500;
    font-size: 0.65rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.$active ? props.theme.colors.primary : props.theme.text};
    ${(props) => props.$homeLink && homeLinkStyles};

    svg {
        width: 20px;
        height: 20px;
        fill: ${(props) => props.$active ? props.theme.colors.primary : props.theme.text};
    }
`;

interface MobileLinkInterface {
    path: string;
    icon: keyof typeof IconVariant;
    label?: string;
    homeLink?: boolean;
}

const mobileLinks: MobileLinkInterface[] = [
    {
        path: '/watchlist',
        icon: 'watchlist',
        label: 'Watchlist',
    },
    {
        path: '/assets',
        icon: 'bitcoin',
        label: 'Assets',
    },
    {
        path: '/home',
        icon: 'home',
        label: 'Home',
    },
    {
        path: '/explore',
        icon: 'explore',
        label: 'Explore',
    },
    {
        path: '/account',
        icon: 'person',
        label: 'Account',
    },
];

export const MobileFooter = () => {
    const path = window.location.pathname;

    return (
        <>
            <MobileFooterContainer>
                <MobileFooterInnerContainer>
                    <MainLinksContainer>
                        {mobileLinks.map((link) => (
                            <StyledLink key={link.path} to={link.path} $active={path === link.path} $homeLink={link.homeLink}>
                                <LinkContainer>
                                    <Icon variant={link.icon} />
                                        {link.label}
                                </LinkContainer>
                            </StyledLink>
                        ))}
                    </MainLinksContainer>
                </MobileFooterInnerContainer>
            </MobileFooterContainer>
            <MobileTradeComponent />
        </>
    );
};
