import React, { useState, useMemo } from 'react';

import styled from 'styled-components';

import { AssetInterface } from '../../@types/types';
import { useMarketAssetsContext } from '../../context/MarketContext';
import { ThemedModal } from '../Modals/ThemedModal';
import { InputField } from '../common/input';
import { ColoredText } from '../common/ColoredText';
import { AssetNameCell } from '../common/AssetNameCell';
import { flexBetweenCentered } from '../../styles/common';

const AssetListItemWrapper = styled.div<{ $asListItem?: boolean }>`
    ${flexBetweenCentered};
    cursor: pointer;
    border: ${(props) => props.$asListItem ? `1px solid ${props.theme.colors.border}` : 'none'};
    border-radius: 5px;
    padding: 1rem 2rem;
    margin-bottom: ${(props) => props.$asListItem && '0.5rem'};
`;

const AssetOptionsWrapper = styled.div`
    max-height: 560px;
    overflow: auto;
    padding: 1rem 2rem;
    position: relative;
`;

const AssetPriceDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const SearchInputWrapper = styled.div`
    margin: 1rem 0;
    width: 100%;

    input {
        width: 90%;
    }
`;

const AssetsContainer = styled.div`
    margin-top: 2rem;
    min-height: 500px;
`;

interface AssetSearchModalProps {
    open: boolean;
    handleClose: () => void;
    handleSelectAsset: (asset: AssetInterface) => void;
}

export const AssetSearchModal: React.FC<AssetSearchModalProps> = ({
    open,
    handleClose,
    handleSelectAsset
}) => {
    const [search, setSearch] = useState<string>('');

    const { assets } = useMarketAssetsContext();

    const filteredAssets = useMemo(() => {
        if (search?.length) {
            return assets?.filter((asset) =>
                asset?.name?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) ||
                asset?.symbol?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())
            );
        }
        return assets;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, assets]);

    return (
        <ThemedModal
            large
            open={open}
            handleClose={handleClose}
        >
            <AssetOptionsWrapper className="assetDropdown">
                <SearchInputWrapper>
                    <InputField
                        outlined
                        placeholder="Search assets"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </SearchInputWrapper>
                <AssetsContainer>
                    {filteredAssets.map((asset) => (
                        <AssetListItemWrapper key={asset.id} $asListItem onClick={() => handleSelectAsset(asset)}>
                            <AssetNameCell asset={asset} disableLink />
                            <AssetPriceDataWrapper>
                                <span>${asset?.currentPrice}</span>
                                <ColoredText element='small' value={asset.dayChangePercentage} text={`${asset?.dayChangePercentage?.toFixed(2)}%`} />
                            </AssetPriceDataWrapper>
                        </AssetListItemWrapper>
                    ))}
                </AssetsContainer>
            </AssetOptionsWrapper>
        </ThemedModal>
    );
};
