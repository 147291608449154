import React, { useMemo } from 'react';

import styled from 'styled-components';
import moment  from 'moment';
import { IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { NotificationsInterface } from '../../../@types/types';

const OuterContainer = styled.div`
    margin-bottom: 1rem;
`;

const NotificationWrapper = styled.a`
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    flex-grow: 1;
`;

const NotificationItemWrapper = styled.div<{ $asMenuItem?: boolean; $read?: boolean; }>`
    display: flex;
    align-items: center;
    margin-inline: 1rem;
    opacity: ${(props) => props.$read ? 0.5 : 1};
    border: 1px dashed ${(props) => props.theme.colors.gray};

    svg {
        fill: ${(props) => props.theme.text};
    }
`;

const DeleteIconButton = styled(IconButton)`
    z-index: 1000;
    &&& {
        margin-right: 1rem;
    }
`;

const NotificationTextWrapper = styled.div`
    padding: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 200px;
    small {
        margin-top: 0.3rem;
        color: ${(props) => props.theme.colors.gray};
    }
`;

interface NotificationItemProps extends NotificationsInterface {
    asMenuItem?: boolean;
    handleDeleteNotification: (id: string) => void;
    handleMarkNotificationRead: (id: string) => void;
}


export const NotificationItem: React.FC<NotificationItemProps> = ({
    id,
    text,
    type,
    url,
    read = false,
    timestamp,
    asMenuItem,
    handleDeleteNotification,
    handleMarkNotificationRead
}) => {

    const buildLink = useMemo(() => {
        switch (type) {
            case 'INFORMATIVE':
                return null;
            case 'TRANSACTION':
                return `/transaction?id=${id}`;
            default:
                return url ?? null;
        }
    }, [type, url, id]);

    return (
        <OuterContainer onClick={() => handleMarkNotificationRead(id)}>
            <NotificationItemWrapper $asMenuItem={asMenuItem} $read={read}>
                <NotificationWrapper href={buildLink}>
                    <NotificationTextWrapper>
                        <span>{text}</span>
                        <small>{moment(timestamp).format('dddd, hh:mm a')}</small>
                    </NotificationTextWrapper>
                </NotificationWrapper>
                <DeleteIconButton onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteNotification(id)
                }}>
                    <HighlightOffIcon />
                </DeleteIconButton>
            </NotificationItemWrapper>
        </OuterContainer>
    )
}