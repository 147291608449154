import styled from 'styled-components';

const StyledFallback = styled.div`
    width: 100vw;
    height: 100vh;
    margin-left: 240px;
    margin-top: 82px;
    background-color: ${(props) => props.theme.body};
`;

export const Fallback = () => {
    return  <StyledFallback />;
};
