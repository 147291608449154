import { useState, useEffect } from 'react';

import styled from 'styled-components';
import { CountUpText } from '../../components/common/CountUpText';
import { AccountMenu, Notifications, QuickTradeButton, Search } from '../../components/Navbar';
import { useNavContext } from '../../context/NavContext';
import { useUserContext } from '../../context/UserContext';
import { useUserBalance } from '../../hooks/useUserBalance';
import { buildBreakpoint } from '../../styles/common';
import { SIDEBAR_WIDTH_IN_PX } from '../../styles/variables';

const NavbarContainer = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    height: 50px;
    margin-left: ${SIDEBAR_WIDTH_IN_PX}px;
    padding: 16px;
    width: -webkit-fill-available;
    border-left: 1px solid ${(props) => props.theme.colors.border};
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    background-color: ${(props) => props.theme.body};
    display: flex;
    align-items: center;

    ${buildBreakpoint('tablet')} {
        margin-left: 0;
        border-left: none;
    };
`;

const NavbarInnerContainer = styled.div`
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const NavTitle = styled.p`
    font-size: 1.2rem;
    font-weight: 500;
`;

const RightOptionsContainer = styled.div`
    display: flex;
    align-items: center;
`;

const UserBalanceContainer = styled.div<{ $visible: boolean }>`
    display: none;

    ${buildBreakpoint('mobile')} {
        display: block;
        transition: 0.2s;
        opacity: ${(props) => props.$visible ? 1 : 0};
    }
`;

export const Navbar: React.FC = () => {
    const [showBalance, setShowBalance] = useState<boolean>(false);
    const {
        user,
        handleDeleteNotification,
        handleMarkNotificationRead,
        signOut
    } = useUserContext();

    const { title } = useNavContext();
    const { userBalance } = useUserBalance();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);
    
    const handleScroll = () => {
        const scrollHeight = window.scrollY;

        if (scrollHeight >= 100) {
            setShowBalance(true);
        } else {
            setShowBalance(false);
        }
    }

    return (
        <NavbarContainer>
            <NavbarInnerContainer>
                <NavTitle>{title}</NavTitle>
                <UserBalanceContainer $visible={showBalance}>
                    <CountUpText value={Number(userBalance?.toFixed(2))} fontSize={1} />
                </UserBalanceContainer>
                <RightOptionsContainer>
                    <QuickTradeButton />
                    <Search />
                    <Notifications
                        notifications={user?.notifications}
                        handleMarkNotificationRead={handleMarkNotificationRead}
                        handleDeleteNotification={handleDeleteNotification}
                    />
                    <AccountMenu user={user} signOut={signOut} />
                </RightOptionsContainer>
            </NavbarInnerContainer>
        </NavbarContainer>
    );
};
