import React, { useMemo } from 'react';

import styled from 'styled-components';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Transaction, TransactionMethod, TransactionType } from '../../@types/types';
import { ThemedModal } from '../Modals/ThemedModal';
import { Icon } from '../common/icons';
import { useFormattedCurrency } from '../../hooks/useFormattedCurrency';
import { buildBreakpoint } from '../../styles/common';

const ModalTitle = styled.div`
    text-align: center;
    margin: 1rem;
    position: relative;
    display: flex;
    justify-content: space-between;

    h3 {
        flex-grow: 1;
    }
`;

const ModalBodyWrapper = styled.div`
    margin-inline: 2rem;
    margin-bottom: 1rem;
    padding: 1rem;
    min-width: 400px;
    border-radius: 5px;

    ${buildBreakpoint('mobile')} {
        min-width: unset;
        max-width: 330px;
    };
`;

const CheckMarkWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;

    svg {
        width: 80px;
        height: 80px;
        fill: ${(props) => props.theme.colors.primary};
    }
`;

const CloseButtonWrapper = styled.div`
    position: absolute;
    right: 0;
`;

const CloseButton = styled(IconButton)`
    svg {
        fill: ${(props) => props.theme.text};
    }
`;

const InnerContainer = styled.div`
    text-align: center;
`;

const TransactionTitle = styled.p<{ $colored?: boolean; }>`
    font-weight: bold;
    font-size: 1.5rem;
    color: ${(props) => props.$colored ? props.theme.colors.green : 'inherit'};
`;

const ViewTransactionLink = styled.a`
    button {
        font-size: 1rem;
        font-family: inherit;
        font-weight: 500;
        border-radius: 25px;
        padding: 0.4rem 2rem;
        color: white;
        cursor: pointer;
        background-color: transparent;
        border: 1px solid ${(props) => props.theme.colors.primary};
    }
`;

interface TransactionInterface extends Transaction {
    transaction?: {
        id: string;
    }
}

interface TransactionModalProps {
    open: boolean;
    setOpen: () => void;
    transaction?: TransactionInterface;
}

export const TransactionModal: React.FC<TransactionModalProps> = ({
    open,
    setOpen,
    transaction
}) => {
    const transactionTotal = useMemo(() => {
        if (transaction?.method === TransactionMethod.LIMIT) {
            return transaction?.transactionTotal;
        }
        if (transaction?.type === TransactionType.BUY) {
            return transaction?.assetsPurchasedSold;
        }
        return transaction?.transactionTotal;
    }, [transaction]);
    
    const formattedTransactionAmount = useFormattedCurrency(transactionTotal, null, 2);

    const transactionTitle = useMemo(() => {
        if (transaction?.method === TransactionMethod.LIMIT) {
            return `$${formattedTransactionAmount} ordered at ${transaction?.purchaseSellAsk}`;
        }
        return (
            `${transaction?.type === TransactionType.BUY ?
                `+ ${formattedTransactionAmount} ${transaction?.symbol} ` :
                `+ ${formattedTransactionAmount} USD`
            }`
        )
    }, [formattedTransactionAmount, transaction?.method, transaction?.purchaseSellAsk, transaction?.symbol, transaction?.type]);

    return (
        <ThemedModal
            open={open}
            handleClose={setOpen}
        >
            <ModalTitle>
                <h3>Transaction Successful!</h3>
                <CloseButtonWrapper>
                    <CloseButton onClick={setOpen}>
                        <CloseIcon />
                    </CloseButton>
                </CloseButtonWrapper>
            </ModalTitle>
            <ModalBodyWrapper>
                <CheckMarkWrapper>
                    <Icon variant="check" />
                </CheckMarkWrapper>
                <InnerContainer>
                    <TransactionTitle $colored={transaction?.method === TransactionMethod.MARKET}>
                        {transactionTitle}
                    </TransactionTitle>
                    <div>
                        <ViewTransactionLink href={`/transaction?id=${transaction?.transaction?.id}`}>
                            <button>
                                View details
                            </button>
                        </ViewTransactionLink>
                    </div>
                </InnerContainer>
            </ModalBodyWrapper>
        </ThemedModal>
    );
}
