import styled, { css } from 'styled-components';
import { buildBreakpoint, flexBetweenCentered } from '../../styles/common';

const fullScreenStyles = css`
    border: none;
    margin-bottom: 2rem;
`;

export const PurchaseSellContainer = styled.div<{ $hideOnMobile?: boolean; $fullScreen?: boolean; }>`
    border-radius: 5px;
    width: 100%;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    border: 1px solid ${(props) => props.theme.colors.border};

    ${buildBreakpoint('mobile')} {
        ${(props) => props.$hideOnMobile && 'display: none'};
        margin-left: 0;
        max-width: unset;
        min-width: unset;
        ${(props) => props.$fullScreen && fullScreenStyles};
    }
`;

export const BuySellContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;
`;

export const activeTabStyles = (buy: boolean) => css`
    border-bottom: 1px solid transparent;
    font-weight: 500;
    border-top: 2px solid ${(props) => props.theme.colors[buy ? 'primary' : 'secondary']};
    color: ${(props) => props.theme.colors[buy ? 'primary' : 'secondary']};
`;

export const BuySellButtonWrapper = styled.div<{ $active?: boolean; $buy?: boolean; }>`
    flex-grow: 1;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    transition: 0.2s;
    border-top: 2px solid transparent;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    border-left: ${(props) => props.$buy ? 'none' : `1px solid ${props.theme.colors[props.$active ? 'secondary' : 'border']}`};
    border-right: ${(props) => props.$buy ? `1px solid ${props.theme.colors[props.$active ? 'primary' : 'border']}` : 'none'};

    ${(props) => props.$active && activeTabStyles(props.$buy)};

    :hover {
        background-color: ${(props) => props.theme.colors.primaryFadedHover};
    }

    label {
        pointer-events: none;
    }
`;

export const InputActionsWrapper = styled.div`
    width: 100%;
    max-width: 400px;
    position: relative;
    display: flex;
    justify-content: center;
`;

export const InputActionsContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`;

export const TotalAssetContainer = styled.div`
    position: relative;
    text-align: center;
    font-weight: 500;
    color: ${(props) => props.theme.colors.gray};

    span {
        color: ${(props) => props.theme.colors.red};
    }
`;

export const BuySellButtonContainer = styled.div`
    margin: 2rem 1rem;
    text-align: center;
`;

export const BuySellButton = styled.button<{ $buy: boolean }>`
    width: 80%;
    max-width: 250px;
    padding: 1rem;
    border-radius: 20px;
    border: 1px solid transparent;
    font-weight: 600;
    color: white;
    font-family: inherit;
    font-size: 1.2rem;
    cursor: pointer;
    transition: 0.2s;
    background-color: ${(props) => props.$buy ? props.theme.colors.primary : props.theme.colors.secondary};

    :disabled {
        background-color: transparent;
        border-color: ${(props) => props.$buy ? props.theme.colors.primary : props.theme.colors.secondary};
        color: ${(props) => props.theme.colors.gray};
    }
`;

export const BalanceFooterWrapper = styled.div`
    ${flexBetweenCentered};
    margin: 1rem;
    font-weight: bold;
    color: ${(props) => props.theme.colors.gray};
`;

export const MarketLimitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem 0;
`;

export const MarketButton = styled.button<{ $active: boolean, $buy: boolean }>`
    border-radius: 5px 0 0 5px;
    border-right: none;
    padding: 0.2rem 1rem;
    font-family: inherit;
    transition: 0.2s;
    cursor: pointer;
    color: ${(props) => props.$active ? 'white' : props.theme.colors.gray};
    border: 1px solid ${(props) => props.theme.colors[props.$buy ? 'primary' : 'secondary']};
    background-color: ${(props) => props.$active ? props.theme.colors[props.$buy ? 'primary' : 'secondary'] : 'transparent'};
`;

export const LimitInputLabel = styled.small`
    position: absolute;
    top: -20px;
`;

export const ErrorMessageWrapper = styled.div`
    text-align: center;
`;

export const LimitButton = styled.button<{ $active: boolean, $buy: boolean }>`
    border-radius: 0 5px 5px 0;
    border-left: none;
    padding: 0.2rem 1rem;
    font-family: inherit;
    transition: 0.2s;
    cursor: pointer;
    color: ${(props) => props.$active ? 'white' : props.theme.colors.gray};
    border: 1px solid ${(props) => props.theme.colors[props.$buy ? 'primary' : 'secondary']};
    background-color: ${(props) => props.$active ? props.theme.colors[props.$buy ? 'primary' : 'secondary'] : 'transparent'};
`;

export const LimitAskInput = styled.input<{ $buy: boolean }>`
    text-align: center;
    font-size: 1rem;
    background: transparent;
    padding: 0.4rem;
    border-radius: 5px;
    font-weight: bold;
    font-family: inherit;
    color: ${(props) => props.theme.text};
    border: 1px solid ${(props) => props.theme.colors[props.$buy ? 'primary' : 'secondary']};
`;