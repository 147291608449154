import React, { useState, useMemo, useEffect } from 'react';

import styled from 'styled-components';

import { TransactionType } from '../../@types/types';
import { useMarketAssetsContext } from '../../context/MarketContext';
import { useGetAssetById } from '../../hooks/useGetAssetById';
import { Icon } from '../../components/common/icons';
import { ThemedModal } from '../../components/Modals/ThemedModal';
import { BuySellComponent } from '../../components/BuySell';
import { buildBreakpoint } from '../../styles/common';

const TradeContainer = styled.div`
    display: none;

    ${buildBreakpoint('tablet')} {
        display: block;
        width: 100%;
        position: fixed;
        bottom: 100px;
        height: 80px;
        border-bottom: 1px solid ${(props) => props.theme.colors.border};
        background-color: ${(props) => props.theme.colors.surface};
    };
`;

const TradeInnerContainer = styled.div`
    padding: 0 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`;

const ExpandableTradeOverlay = styled.div<{ $expanded: boolean; }>`
    display: none;

    ${buildBreakpoint('tablet')} {
        display: ${(props) => props.$expanded ? 'block' : 'none'}; 
        height: 100vh;
        width: 100%;
        position: fixed;
        transition: 0.2s;
        background-color: rgba(0,0,0,0.5);
        bottom: ${(props) => props.$expanded ? 0 : -200}px;
    };
`;

const ExpandableTradeContainer = styled.div<{ $expanded: boolean; }>`
    display: none;

    ${buildBreakpoint('tablet')} {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: fixed;
        transition: 0.2s;
        border-radius: 5px 5px 0 0;
        z-index: 10;
        bottom: ${(props) => props.$expanded ? 0 : -200}px;
        background-color: ${(props) => props.theme.colors.surface};
    };
`;

const TradeOptionButton = styled.div`
    padding: 2rem;
    display: flex;
    align-items: center;
`;

const TradeOptionIcon = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 2rem;
`;

const TradeItemData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
        font-weight: bold;
    }

    small {
        font-weight: bold;
        color: ${(props) => props.theme.colors.gray};
    }
`;

const TradeButtonContainer = styled.div`
    width: 100%;
    justify-content: center;
    display: flex;
    margin 2rem 0;
`;

const TradeButton = styled.button`
    font-size: 1rem;
    font-family: inherit;
    padding: 0.4rem 3rem;
    min-width: 200px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    transition: 0.2s;
    color: ${(props) => props.theme.text};
    background-color: ${(props) => props.theme.colors.secondary};

    :hover {
        background-color: ${(props) => props.theme.colors.secondaryHover};
    }
`;


export const MobileTradeComponent: React.FC = () => {
    const [assetId, setAssetId] = useState<string>();
    const [transactionType, setTransactionType] = useState<TransactionType>(TransactionType.BUY);

    const path = window.location.pathname;

    const [tradeOpen, setTradeOpen] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const { userAssets } = useMarketAssetsContext();
    const { asset } = useGetAssetById(assetId);
    
    const userAsset = useMemo(() => {
        return userAssets?.find((item) => item.id === asset?.id) ?? null;
    }, [userAssets, asset]);

    useEffect(() => {
        if (path === '/asset') {
            const queryParams = new URLSearchParams(window.location.search).get('pair') ?? null;

            if (queryParams) {
                setAssetId(queryParams);
            }
        }
    }, [path]);

    const onTradeButtonClicked = (type: TransactionType) => {
        setTransactionType(type);
        setModalOpen(true);
        setTradeOpen(false);
    };

    if (path !== '/asset') return null;

    return (
        <>            
                <TradeContainer>
                    <TradeInnerContainer>
                        <TradeButtonContainer>
                            <TradeButton onClick={() => setTradeOpen(!tradeOpen)}>Trade</TradeButton>
                        </TradeButtonContainer>
                    </TradeInnerContainer>
                </TradeContainer>
                <ExpandableTradeContainer $expanded={tradeOpen}>
                    <TradeOptionButton onClick={() => onTradeButtonClicked(TransactionType.BUY)}>
                        <TradeOptionIcon>
                            <Icon variant="add" />
                        </TradeOptionIcon>
                        <TradeItemData>
                            <span>Buy {asset?.symbol}</span>
                            <small>${asset?.currentPrice}</small>
                        </TradeItemData>
                    </TradeOptionButton>
                    {userAsset ? (
                        <TradeOptionButton onClick={() => onTradeButtonClicked(TransactionType.SELL)}>
                            <TradeOptionIcon>
                                <Icon variant="sell" />
                            </TradeOptionIcon>
                            <TradeItemData >
                                <span>Sell {asset?.symbol}</span>
                                <small>{userAsset?.shares}</small>
                            </TradeItemData>
                        </TradeOptionButton>
                    ) : null}
                </ExpandableTradeContainer>
                <ExpandableTradeOverlay $expanded={tradeOpen} onClick={() => setTradeOpen(false)} />
                <ThemedModal
                    large
                    fullScreen
                    open={modalOpen}
                    handleClose={() => setModalOpen(false)}
                >
                    <BuySellComponent
                        fullScreen
                        formattedAsset={asset}
                        initialTransactionType={transactionType}
                        onCloseMobileModal={() => setModalOpen(false)}
                    />
                </ThemedModal>
            </>
    )
}