import React, { useMemo } from 'react';

import styled from 'styled-components';

import { TransactionActiveInput, TransactionType } from '../../../@types/types';
import { useMarketAssetsContext } from '../../../context/MarketContext';

const TransactionAmountLabel = styled.p`
    font-weight: 500;
    text-align: center;
    color: ${(props) => props.theme.colors.gray};
`;

interface CalculatedTransactionAmountProps {
    symbol: string;
    transactionAmount: number;
    transactionType: TransactionType;
    transactionActiveInput?: TransactionActiveInput;
}

export const CalculatedTransactionAmount: React.FC<CalculatedTransactionAmountProps> = ({
    symbol,
    transactionType,
    transactionAmount,
    transactionActiveInput = TransactionActiveInput.USD
}) => {
    const { getAssetBySymbol } = useMarketAssetsContext();

    const calculatedAmount = useMemo(() => {
        switch (transactionType) {
            case TransactionType.BUY:
                if (transactionActiveInput === TransactionActiveInput.USD) {
                    return (Number(transactionAmount) / getAssetBySymbol(symbol)?.currentPrice).toFixed(2);
                }
                return (Number(transactionAmount) * getAssetBySymbol(symbol)?.currentPrice);
            case TransactionType.SELL:
                if (transactionActiveInput === TransactionActiveInput.SYMBOL) {
                    return (Number(transactionAmount) / getAssetBySymbol(symbol)?.currentPrice).toFixed(2);
                }
                return (Number(transactionAmount) * getAssetBySymbol(symbol)?.currentPrice);
            default:
                return 0;

        }
    }, [transactionType, transactionActiveInput, transactionAmount, getAssetBySymbol, symbol]);

    const calculatedAmountLabel = useMemo(() => {
        switch (transactionType) {
            case TransactionType.BUY:
                return `${calculatedAmount} ${transactionActiveInput === TransactionActiveInput.USD ? symbol : 'USD'}`;
            case TransactionType.SELL:
                return `${calculatedAmount} ${transactionActiveInput === TransactionActiveInput.USD ? 'USD' : symbol}`;
        };
    }, [calculatedAmount, symbol, transactionActiveInput, transactionType]);

    return (
        <TransactionAmountLabel>{calculatedAmountLabel}</TransactionAmountLabel>
    );
};
