import React from 'react';
import { Navigate } from 'react-router-dom';

import { useUserContext } from '../context/UserContext';
import { GuestLayout } from '../layout/GuestLayout';

export const NonAuthRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { isLoggedIn, initialized } = useUserContext();

    if (!initialized) {
        return null;
    }    

    if (isLoggedIn) {
        return <Navigate to="/home" />;
    }

    return <GuestLayout>{children}</GuestLayout>;
}