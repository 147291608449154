import { lazy, Suspense } from 'react';

import { AuthLayout } from '../layout/AuthLayout';
import { GuestLayout } from '../layout/GuestLayout';

import { AuthRoute } from './AuthRoute';
import { NonAuthRoute } from './NonAuthRoute';
import { Fallback } from './Fallback';
import { AgnosticLayout } from '../layout/AgnosticLayout';
import { LanderLayout } from '../layout/LanderLayout';

const Loadable = (Component: any) => (props: any) => (
    <Suspense fallback={<Fallback />}>
        <Component {...props} />
    </Suspense>
);

const HomePage = Loadable(lazy(() => import('../pages/Home')));
const AssetDataPage = Loadable(lazy(() => import('../pages/AssetData')));
const AssetsPage = Loadable(lazy(() => import('../pages/Assets')));
const ExplorePage = Loadable(lazy(() => import('../pages/Explore')));
const WatchlistPage = Loadable(lazy(() => import('../pages/Watchlist')));
const AllTransactionsPage = Loadable(lazy(() => import('../pages/AllTransactions')));
const TransactionPage = Loadable(lazy(() => import('../pages/Transaction')));
const AccountPage = Loadable(lazy(() => import('../pages/Account')));
const OrderDetailsPage = Loadable(lazy(() => import('../pages/OrderDetails')));
const NotificationsPage = Loadable(lazy(() => import('../pages/Notifications')));
const LearnAndEarnPage = Loadable(lazy(() => import('../pages/LearnAndEarn')));
const UserProfilePage = Loadable(lazy(() => import('../pages/UserProfile')));
const LeaderboardPage = Loadable(lazy(() => import('../pages/Leaderboard')));
const LanderPage = Loadable(lazy(() => import('../pages/Lander')));
const LogoutPage = Loadable(lazy(() => import('../pages/Logout')));

const LoginPage = Loadable(lazy(() => import('../pages/Login')));
const SignUpPage = Loadable(lazy(() => import('../pages/SignUp')));
const ForgotPasswordPage = Loadable(lazy(() => import('../pages/ForgotPassword')));
const PostVerifyPage = Loadable(lazy(() => import('../pages/PostEmailVerification')));
const AuthDemoUserPage = Loadable(lazy(() => import('../pages/AuthDemoUser')))

const NotFoundPage = Loadable(lazy(() => import('../pages/404')));

const IconsPage = Loadable(lazy(() => import('../pages/Icons')));

export const routes = [
    {
        path: '/',
        element: (
            <LanderLayout>
                <LanderPage />
            </LanderLayout>
        )
    },
    {
        path: '/home',
        element: (
            <AuthRoute>
                <AuthLayout>
                    <HomePage />
                </AuthLayout>
            </AuthRoute>
        )
    },
    {
        path: '/asset',
        element: (
            <AuthRoute>
                <AuthLayout>
                    <AssetDataPage />
                </AuthLayout>
            </AuthRoute>
        )
    },
    {
        path: '/assets',
        element: (
            <AuthRoute>
                <AuthLayout>
                    <AssetsPage />
                </AuthLayout>
            </AuthRoute>
        )
    },
    {
        path: '/order',
        element: (
            <AuthRoute>
                <AuthLayout>
                    <OrderDetailsPage />
                </AuthLayout>
            </AuthRoute>
        )
    },
    {
        path: '/account',
        element: (
            <AuthRoute>
                <AuthLayout>
                    <AccountPage />
                </AuthLayout>
            </AuthRoute>
        )
    },
    {
        path: '/leaderboard',
        element: (
            <AuthRoute>
                <AuthLayout>
                    <LeaderboardPage />
                </AuthLayout>
            </AuthRoute>
        )
    },
    {
        path: '/notifications',
        element: (
            <AuthRoute>
                <AuthLayout>
                    <NotificationsPage />
                </AuthLayout>
            </AuthRoute>
        )
    },
    {
        path: '/transaction',
        element: (
            <AuthRoute>
                <AuthLayout>
                    <TransactionPage />
                </AuthLayout>
            </AuthRoute>
        )
    },
    {
        path: '/all-transactions',
        element: (
            <AuthRoute>
                <AuthLayout>
                    <AllTransactionsPage />
                </AuthLayout>
            </AuthRoute>
        )
    },
    {
        path: '/learn',
        element: (
            <AuthRoute>
                <AuthLayout>
                    <LearnAndEarnPage />
                </AuthLayout>
            </AuthRoute>
        )
    },
    {
        path: '/explore',
        element: (
            <AuthRoute>
                <AuthLayout>
                    <ExplorePage />
                </AuthLayout>
            </AuthRoute>
        )
    },
    {
        path: '/watchlist',
        element: (
            <AuthRoute>
                <AuthLayout>
                    <WatchlistPage />
                </AuthLayout>
            </AuthRoute>
        )
    },
    {
        path: '/login',
        element: (
            <NonAuthRoute>
                <GuestLayout>
                    <LoginPage />
                </GuestLayout>
            </NonAuthRoute>
        )
    },
    {
        path: '/logout',
        element: (
            <AuthRoute>
                <LogoutPage />
            </AuthRoute>
        )
    },
    {
        path: '/sign-up',
        element: (
            <NonAuthRoute>
                <GuestLayout>
                    <SignUpPage />
                </GuestLayout>
            </NonAuthRoute>
        )
    },
    {
        path: '/forgot-password',
        element: (
            <NonAuthRoute>
                <GuestLayout>
                    <ForgotPasswordPage />
                </GuestLayout>
            </NonAuthRoute>
        )
    },
    {
        path: '/verify-email',
        element: (
            <GuestLayout>
                <PostVerifyPage />
            </GuestLayout>
        )
    },
    {
        path: '/user',
        element: (
            <AgnosticLayout>
                <UserProfilePage />
            </AgnosticLayout>
        )
    },
    {
        path: '/preview',
        element: (
            <AgnosticLayout>
                <AuthDemoUserPage />
            </AgnosticLayout>
        )
    },
    {
        path: '/icons',
        element: (
            <AuthLayout>
                <IconsPage />
            </AuthLayout>
        )
    },
    {
        path: '*',
        element: <NotFoundPage />
    }
];
