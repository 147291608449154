import React from 'react';

import styled from 'styled-components';

import BitSimLogo from './BitsimLogo.png';

const TitleContainer = styled.div<{ $width?: number }>`
    display: flex;
    align-items: flex-end;
    h2 {
        margin: 0rem;
        margin-left: -0.4rem;
        margin-bottom: 0.4rem;
    }

    img {
        width: ${(props) => props.$width ?? 50}px;
    }
`;

export const SiteLogo: React.FC<{ width?: number }> = ({ width }) => {
    return (
        <TitleContainer $width={width}>
            <img src={BitSimLogo} alt="Bitsim logo" />
            <h2>itSim</h2>
        </TitleContainer>
    );
};
