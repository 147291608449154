import React, { useState } from 'react';

import styled from 'styled-components';
import { ActionButtons } from '.';

import { Icon } from '../../components/common/icons';
import { buildBreakpoint } from '../../styles/common';

const MobileMenuWrapper = styled.div`
    display: none;

    ${buildBreakpoint('mobile')} {
        display: block;
        position: relative;
    }
`;

const MobileMenuContainer = styled.nav<{ $open: boolean; }>`
    height: 100vh;
    width: 100vw;
    position: fixed;
    right: ${(props) => props.$open ? 0 : -460}px;
    top: 0;
    background-color: ${({ theme }) => theme.body};
    transition: 0.3s;
    pointer-events: ${(props) => props.$open ? 'all' : 'none'};
`;

const MenuInnerWrapper = styled.div`
    padding: 1rem;
    margin-inline: 2rem;
`;

const MenuTopContainer = styled.div`
    margin-top: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const MenuButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    align-items: center;
    min-height: 150px;
    justify-content: space-around;
`;

export const MobileMenu: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);

    const handleClick = () => {
        setOpen(!open);
    }

    return (
        <MobileMenuWrapper>
            <div onClick={handleClick}>
                <Icon variant='menu' width={30} height={30} />
            </div>
            <MobileMenuContainer $open={open}>
                <MenuInnerWrapper>
                    <MenuTopContainer>
                        <div onClick={handleClick}>
                            <Icon variant='close' width={30} height={30} />
                        </div>
                    </MenuTopContainer>
                    <MenuButtonsContainer>
                        <ActionButtons asMobile />
                    </MenuButtonsContainer>
                </MenuInnerWrapper>
            </MobileMenuContainer>
        </MobileMenuWrapper>
    );
};
