import { UserInterface } from "../firebase";

export interface AssetInterface {
    id: string;
    name: string;
    symbol: string;
    currentPrice: number;
    open?: string;
    high: string;
    low: string;
    dayChangePercentage: number;
    image: string;
    url: string;
    marketCap?: number;
    volume?: number;
    sparkline?: number[];
    shares?: number;
}

export interface TradeHistoryInterface {
    id: string;
    type: TradeType;
    method: TransactionMethod;
    timestamp: number;
    symbol: string;
    name: string;
    symbolPrice: number;
    assetAmount: number;
    sellPrice?: number;
    purchasePrice?: number;
}

export interface BalanceHistory {
    timestamp: number;
    balance: number;
}

export interface OpenPositionInterface {
    sharePrices: number[];
    shares: number;
};

export interface NotificationsInterface {
    id: string;
    type: NotificationType;
    timestamp: number;
    text: string;
    read?: boolean;
    url?: string;
}

export interface LimitPositionInterface {
    id: string;
    symbol: string;
    limitAsk: number;
    purchaseAsk?: number;
    sellAsk?: number;
    type: TradeType;
    expires: number;
};

export interface SettingsInterface {
    key: string;
    value: boolean;
}

export interface User extends Partial<UserInterface> {
    id?: string;
    avatar?: string | null;
    name?: string | null;
    username?: string;
    balance?: number;
    assets?: UserAssetInterface[];
    walletAddress?: string;
    history?: TradeHistoryInterface[];
    startingBalance?: number;
    watchlist?: string[];
    balanceHistory?: BalanceHistory[];
    openPositions?: OpenPositionInterface[];
    limitPositions?: LimitPositionInterface[];
    notifications?: NotificationsInterface[];
    joined?: number;
    settings?: SettingsInterface[];
    percentageGainLoss?: number;
    lifetimeEarnings?: string;
    userType?: UserType;
    following?: string[];
    followers?: string[];
}

export interface ProviderProps {
    children: React.ReactNode;
}

export interface UserAssetInterface extends AssetInterface {
    shares: number;
    average?: number;
    openPositions?: OpenPositionInterface[];
}

export interface RequestError {
    error: string;
}

export type TradeType = 'PURCHASE' | 'SELL' | 'WORKING';

export enum NotificationType {
    NEW_FOLLOWER = 'NEW_FOLLOWER',
    INFORMATIVE = 'INFORMATIVE',
    TRANSACTION = 'TRANSACTION'
}


export enum TransactionMethod {
    MARKET = 'MARKET',
    LIMIT = 'LIMIT'
};

export enum TransactionType {
    BUY = 'BUY',
    SELL = 'SELL'
};

export enum TransactionActiveInput {
    USD = 'USD',
    SYMBOL = 'SYMBOL'
};

export enum UserType {
    PERSONAL = 'PERSONAL',
    DEMO = 'DEMO',
    STUDENT = 'STUDENT',
    TEACHER = 'TEACHER'
}

export interface Transaction {
    id?: string;
    type: TransactionType;
    method: TransactionMethod;
    symbol: string;
    assetsPurchasedSold: number;
    transactionTotal?: number;
    purchaseSellAsk?: number;
}

export interface DateRange {
    label: string;
    value: number | string;
    hideOnMobile?: boolean;
};


export type PercentageChangeType = 'gain' | 'loss' | 'neutral';
