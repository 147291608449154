import { useRoutes } from 'react-router-dom';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { routes } from './routes';
import { lightTheme, darkTheme } from './styles/theme';
import { GlobalStyles } from './styles/globalStyles';
import { useThemeContext, ThemeMode } from './context/ThemeContext';

export const App: React.FC = () => {
    const allPages = useRoutes(routes);
    const { theme } = useThemeContext();

    return (
        <ThemeProvider theme={theme === ThemeMode.DARK ? darkTheme as DefaultTheme : lightTheme}>
            <GlobalStyles />
            {allPages}
        </ThemeProvider>
    );
};
