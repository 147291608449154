import { useMemo } from 'react';

import styled from 'styled-components';
import { IconButton } from '@mui/material';
import WbSunnyIcon from '@mui/icons-material/WbSunny';

import { useThemeContext, ThemeMode } from '../../../context/ThemeContext';
import { Icon } from '../../common/icons';
import { buildBreakpoint } from '../../../styles/common';

const IconWrapper = styled(IconButton)`
    &&& {
        display: flex;
        border: 1px solid ${(props) => props.theme.colors.border};
        svg {
            fill: ${(props) => props.theme.text};
        }

        ${buildBreakpoint('tablet')} {
            display: none;
        };
    }
`;

export const ThemeModeSelector: React.FC = () => {
    const { theme, toggleTheme } = useThemeContext();

    const icon = useMemo(() => {
        if (theme === ThemeMode.DARK) {
            return <WbSunnyIcon />;
        }
        return <Icon variant='dark' />;
    }, [theme]);
    
    return (
        <IconWrapper onClick={toggleTheme}>
            {icon}
        </IconWrapper>
    );
};
