import React from 'react';

import styled from 'styled-components';
import { Skeleton } from '@mui/material';

const StyledSkeletonLoader = styled(Skeleton)`
    &&& {
        background-color: ${(props) => props.theme.colors.border};
        max-width: 100%;
    }
`;

const DEFAULT_HEIGHT = 50;

export type SkeletonVariant = "text" | "rectangular" | "rounded" | "circular";

interface SkeletonLoaderProps {
    width?: number;
    height?: number;
    variant?: SkeletonVariant;
    numLoaders?: number;
}

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
    width,
    height = DEFAULT_HEIGHT,
    variant = "text"
}) => {
    return (
        <StyledSkeletonLoader
            animation="pulse"
            width={width ?? '100%'}
            height={height}
            variant={variant}
        />
    );
};
