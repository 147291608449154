import React from 'react';

import styled from 'styled-components';

const StyledErrorMessage = styled.p<{ $fontSize?: number }>`
    font-size: ${(props) => props.$fontSize ?? 0.6}rem;
    color: ${(props) => props.theme.colors.red};
`;

interface ErrorMessageProps {
    text?: string;
    fontSize?: number;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ text, fontSize }) => {
    return <StyledErrorMessage $fontSize={fontSize}>{text}</StyledErrorMessage>
};
