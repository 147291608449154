import { useEffect, useState } from "react";

interface UseWindowWidthOutput {
    windowWidth: number;
}

export const useWindowWidth = (): UseWindowWidthOutput => {
    const [windowWidth, setWindowWidth] = useState<number>();
    
    useEffect(() => {
        setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleWindowWidth);

        return () => {
            window.removeEventListener('resize', handleWindowWidth);
        }
    }, []);

    const handleWindowWidth = () => {
        setWindowWidth(window.innerWidth);
    };

    return { windowWidth };
};
