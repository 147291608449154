import React from 'react';

import { useUserContext } from '../../context/UserContext';
import { AuthLayout } from '../AuthLayout';
import { GuestLayout } from '../GuestLayout';
import { ProviderProps } from '../../@types/types';

export const AgnosticLayout: React.FC<ProviderProps> = ({ children }) => {
    const { user } = useUserContext();

    if (user?.id) {
        return <AuthLayout>{children}</AuthLayout>
    }
    return <GuestLayout>{children}</GuestLayout>
};
