import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { AssetInterface } from '../../../@types/types';
import { useMarketAssetsContext } from '../../../context/MarketContext';
import { AssetSearchModal } from '../../AssetSearchModal';

const DropdownContainer = styled.div`
    margin: 1rem;
    display: flex;
    width: 100%;
    max-width: -webkit-fill-available;
    text-align: center;
    justify-content: center;
`;

const SelectDropdown = styled.div`
    font-size: 1rem;
    font-family: inherit;
    width: 100%;
    max-width: 200px;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    border: 1px solid ${(props) => props.theme.colors.border};
`;

const SelectedAssetWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
        margin-left: 1rem;
    }
`;

interface AssetDropdownProps {
    onSelectedAsset: (asset: AssetInterface) => void;
};

export const AssetDropdown: React.FC<AssetDropdownProps> = ({ onSelectedAsset }) => {
    const { assets } = useMarketAssetsContext();
    
    const [open, setOpen] = useState<boolean>(false);
    const [selectedAsset, setSelectedAsset] = useState(assets[0]);

    useEffect(() => {
        if (!selectedAsset) {
            setSelectedAsset(assets[0]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assets]);

    const handleSelectAsset = (asset) => {
        setSelectedAsset(asset);
        onSelectedAsset(asset);
        setOpen(false);
    };

    return (
        <DropdownContainer>
            <SelectDropdown onClick={() => setOpen(!open)}>
                <SelectedAssetWrapper>
                    <img src={selectedAsset?.image} width={20} alt={selectedAsset?.name} />
                    <span>{selectedAsset?.name ?? selectedAsset?.symbol}</span>                    
                </SelectedAssetWrapper>
            </SelectDropdown>
            <AssetSearchModal
                open={open}
                handleClose={() => setOpen(!open)}
                handleSelectAsset={handleSelectAsset}
            />
        </DropdownContainer>
    )
};
