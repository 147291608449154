import { useMemo } from 'react';
import styled from 'styled-components';
import { AssetInterface } from '../../../@types/types';
import { LoadableElement } from '../../LoadableElement';

const Container = styled.a`
    display: flex;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
`;

const Wrapper = styled.div`
    display: flex;
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const NameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
`;

interface AssetNameCellProps {
    asset: AssetInterface;
    disableLink?: boolean;
    trimName?: boolean;
}

export const AssetNameCell: React.FC<AssetNameCellProps> = ({ asset, trimName = true, disableLink = false }) => {
    const assetName = useMemo(() => trimName && asset?.name?.length > 14 ? asset?.name?.substring(0, 12) + '...' : asset?.name, [asset?.name, trimName]);
    if (disableLink) {
        return (
            <Wrapper>
                <ImageWrapper>
                    <LoadableElement
                        loading={!asset?.image}
                        width={30}
                        height={30}
                        type='circular'
                    >
                        <img src={asset?.image} width="30px" alt={asset?.id} />
                    </LoadableElement>
                </ImageWrapper>
                <NameWrapper>
                    <LoadableElement
                        loading={!assetName}
                        width={150}
                    >
                        <span>{assetName}</span>
                    </LoadableElement>
                    <LoadableElement
                        loading={!assetName}
                        width={60}
                    >
                        <small>{asset?.symbol}</small>
                    </LoadableElement>
                </NameWrapper>
            </Wrapper>
        )
    }
    return (
        <Container href={`/asset?pair=${asset?.id}`}>

            <ImageWrapper>
                <LoadableElement
                    loading={!asset?.image}
                    width={30}
                    height={30}
                    type='circular'
                >
                <img src={asset?.image} width="30px" alt={asset?.id} />
            </LoadableElement>
            </ImageWrapper>
            <NameWrapper>
                <LoadableElement
                    loading={!assetName}
                    width={150}
                >
                    <span>{assetName}</span>
                </LoadableElement>
                <LoadableElement
                    loading={!assetName}
                    width={60}
                >
                    <small>{asset?.symbol}</small>
                </LoadableElement>
            </NameWrapper>
        </Container>
    );
};
