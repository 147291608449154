import React, { useState } from 'react';

import styled from 'styled-components';
import {
    Box,
    Avatar,
    ListItemIcon,
    IconButton,
    Tooltip,
    Badge
} from '@mui/material';

import { paperProps } from './PaperProps';
import { Icon } from '../../common/icons';
import { WalletAddress } from '../../common/WalletAddress';
import {
    AccountMenuContainer,
    UserInfoWrapper,
    StyledMenu,
    StyledMenuItem,
    StyledDivider,
    ItemsWrapper,
    LinkWrapper
} from '../../common/styles/UserMenu';
import { User } from '../../../@types/types';
import { DarkModeToggle } from './DarkModeToggle';
import { buildBreakpoint } from '../../../styles/common';

const MobileNotificationBadge = styled(Badge)`
    display: none;

    ${buildBreakpoint('tablet')} {
        &&& {
            display: block;
            .MuiBadge-badge {
                font-family: inherit;
                top: 5px;
                color: ${(props) => props.theme.text};
                background-color: ${(props) => props.theme.colors.primary};
            }
        }
    };
`;

const MenuButtonWrapper = styled.div`
    color: ${(props) => props.theme.text};
`;

const MenuButtonInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    justify-content: space-between;

    div {
        display: flex;
        align-items: center;
    }

    svg {
        margin-right: 0.8rem;
    }
`;

interface AccountMenuProps {
    user?: User;
    signOut: () => void;
}

interface LinksInterface {
    label: string;
    icon: React.ReactNode;
    to?: string;
    mobileOnly?: boolean;
}

interface MenuComponentProps {
    anchorEl: null | HTMLElement;
    open: boolean;
    username: string;
    asMobileMenu?: boolean;
    notifications: any[];
    handleClose: () => void;
    signOut: () => void;
};

interface MenuItemProps extends LinksInterface {
    notifications: any[];
    handleClose: () => void;
}

const links: LinksInterface[] = [
    {
        label: 'Account',
        icon: <Icon variant='person' />,
        to: '/account'
    },
    {
        label: 'Watchlist',
        icon: <Icon variant="watchlist" />,
        to: '/watchlist'
    },
    {
        label: 'Transactions',
        icon: <Icon variant="receipt" />,
        to: '/all-transactions'
    },
    {
        label: 'Leaderboard',
        icon: <Icon variant="trophy" />,
        to: '/leaderboard',
        mobileOnly: true
    },
    {
        label: 'Notifications',
        icon: <Icon variant="notification" />,
        to: '/notifications',
        mobileOnly: true
    },
    {
        label: 'Dark mode',
        icon: <Icon variant="moon" />,
    },
];

const MenuItemComponent: React.FC<MenuItemProps> = ({
    label,
    icon,
    to,
    notifications,
    mobileOnly = false,
    handleClose
}) => {
    if (to) {
        return (
            <LinkWrapper
                $mobileOnly={mobileOnly}
                to={to}
                onClick={handleClose}
            >
                <StyledMenuItem>
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    {label} {label === 'Notifications' && notifications?.length > 0 && `( ${notifications.length} )`}
                </StyledMenuItem>
            </LinkWrapper>
        )
    }

    return (
        <MenuButtonWrapper>
            <MenuButtonInnerWrapper>
                <div>
                    {icon}
                    {label}
                </div>
                <div>
                    <DarkModeToggle />
                </div>
            </MenuButtonInnerWrapper>
        </MenuButtonWrapper>
    )
}

export const MenuComponent: React.FC<MenuComponentProps> = ({
    anchorEl,
    open,
    username,
    notifications,
    signOut,
    handleClose
}) => {
    return (
        <StyledMenu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={paperProps({ width: 300 })}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <UserInfoWrapper>
                <span>{username}</span>
                <WalletAddress />
            </UserInfoWrapper>
            <StyledDivider />
            <ItemsWrapper>
                {links.map((item, i) => (
                    <MenuItemComponent
                        key={i}
                        {...item}
                        notifications={notifications}
                        handleClose={handleClose}
                    />
                ))}
            </ItemsWrapper>
            <StyledDivider />
            <ItemsWrapper>
                <StyledMenuItem onClick={signOut}>
                    <ListItemIcon>
                        <Icon variant="logout" />
                    </ListItemIcon>
                    Logout
                </StyledMenuItem>
            </ItemsWrapper>
        </StyledMenu>
    )
}

export const AccountMenu: React.FC<AccountMenuProps> = ({ user, signOut }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (

        <AccountMenuContainer>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MobileNotificationBadge badgeContent={user?.notifications?.length}>
                            {user?.avatar ? (
                                <Avatar src={user.avatar} sx={{ width: 50, height: 50 }} />
                            ) : (
                                <Avatar sx={{ width: 50, height: 50 }}>{user?.username?.charAt(0)?.toLocaleUpperCase()}</Avatar>
                            )}
                        </MobileNotificationBadge>
                    </IconButton>
                </Tooltip>
            </Box>
            <MenuComponent
                anchorEl={anchorEl}
                open={open}
                username={user?.username}
                notifications={user?.notifications}
                signOut={signOut}
                handleClose={handleClose}
            />
        </AccountMenuContainer>
    );
}
