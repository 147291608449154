import React, { useState, useMemo, ReactElement } from 'react';

import styled from 'styled-components';

import { Tooltip } from '@mui/material';
import { useUserContext } from '../../../context/UserContext';
import { formatWalletAddress } from '../../../utils/formatWalletAddress';

const StyledWalletAddress = styled.small`
    cursor: pointer;
`;

interface WalletAddressProps {
    userAddress?: string;
    trim?: boolean;
}

export const WalletAddress = ({ userAddress = null, trim = true }: WalletAddressProps): ReactElement => {
    const [copied, setCopied] = useState<boolean>(false);
    const { user } = useUserContext();

    const wallet = useMemo(() => userAddress ?? user?.walletAddress, [userAddress, user?.walletAddress]);

    const handleCopyWalletAddress = () => {
        navigator.clipboard.writeText(wallet);
        setCopied(true);
    };

    return (
        <Tooltip
            arrow
            title={copied ? 'Copied!' : 'Copy'}
            placement="right"
            onMouseLeave={() => setTimeout(() => setCopied(false), 500)}
        >
            <StyledWalletAddress onClick={handleCopyWalletAddress}>
                {formatWalletAddress(wallet, trim)}
            </StyledWalletAddress>
        </Tooltip>
    );
};
