import React, { forwardRef, Ref, ReactElement } from 'react';

import styled, { css } from 'styled-components';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { ProviderProps } from '../../@types/types';
import { buildBreakpoint } from '../../styles/common';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
      children: ReactElement<any, any>;
    },
    ref: Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const fullScreenStyles = css`
    position: absolute;
    bottom: 0;
    max-width: unset;
    border-radius: 10px 10px 0 0;
`;

const StyledDialog = styled(Dialog)<{ $fullScreen?: boolean; }>`
    .MuiPaper-root {
        overflow: hidden;
        font-family: inherit;
        color: ${(props) => props.theme.text};
        background-color: ${(props) => props.theme.colors.surface};
    }

    ${buildBreakpoint('mobile')} {
        .MuiPaper-root {
            margin: 0;
            width: 100%;
            max-width: 340px;
            ${(props) => props.$fullScreen && fullScreenStyles};
        }
    };
`;

interface ThemedModalProps extends ProviderProps {
    open: boolean;
    large?: boolean;
    fullScreen?: boolean;
    handleClose: () => void;
}

export const ThemedModal: React.FC<ThemedModalProps> = ({
    open,
    large = false,
    fullScreen = false,
    handleClose,
    children
}) => {

    return (
        <StyledDialog
            $fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            fullWidth={large}
            maxWidth={"sm"}
        >
            {children}
        </StyledDialog>
    );
};
