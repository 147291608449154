import React, { useMemo, useState } from 'react';

import styled from 'styled-components';
import { IconButton, Badge } from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import { paperProps } from '../AccountMenu/PaperProps';
import { NotificationsInterface } from '../../../@types/types';
import {  StyledMenu, ItemsWrapper } from '../../common/styles/UserMenu';
import { NotificationItem } from '../../common/NotificationItem';
import { buildBreakpoint } from '../../../styles/common';

const Container = styled.div`
    ${buildBreakpoint('tablet')} {
        display: none;
    };
`;

const EmptyNotifactions = styled.h3`
    text-align: center;
`;

const IconWrapper = styled(IconButton)`
    width: 50px;
    height: 50px;
    &&& {
        display: flex;
        border: 1px solid ${(props) => props.theme.colors.border};

        svg {
            fill: ${(props) => props.theme.text};
        }

        .MuiBadge-badge {
            font-family: inherit;
            top: -5px;
            right: -5px;
            color: ${(props) => props.theme.text};
            background-color: ${(props) => props.theme.colors.primary};
        }
    }
`;

interface NotificationsProps {
    notifications: NotificationsInterface[];
    handleMarkNotificationRead: (id: string) => void;
    handleDeleteNotification: (id: string) => void;
};

interface NotificationsMenuProps extends NotificationsProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    handleClose: () => void;
}

const NotificationsMenu: React.FC<NotificationsMenuProps> = ({
    notifications,
    open,
    anchorEl,
    handleDeleteNotification,
    handleMarkNotificationRead,
    handleClose
}) => {
    return (
        <StyledMenu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={paperProps({ width: 350 })}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {notifications?.length ? (
                <ItemsWrapper>
                    {notifications?.map((item) => (
                        <NotificationItem
                            {...item}
                            key={item.id}
                            asMenuItem
                            handleMarkNotificationRead={handleMarkNotificationRead}
                            handleDeleteNotification={handleDeleteNotification}
                        />
                    ))}
                </ItemsWrapper>
            ) : (
                <EmptyNotifactions>No notifications</EmptyNotifactions>
            )}
        </StyledMenu>
    )
}

export const Notifications: React.FC<NotificationsProps> = ({
    notifications,
    handleMarkNotificationRead,
    handleDeleteNotification
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const notificationCount = useMemo(() => {
        return notifications?.filter((item) => !item.read)?.length;
    }, [notifications]);

    return (
        <>        
            <Container>
                <IconWrapper onClick={handleClick}>
                    <Badge badgeContent={notificationCount}>
                        <NotificationsActiveIcon />
                    </Badge>
                </IconWrapper>
            </Container>
            <NotificationsMenu
                open={open}
                anchorEl={anchorEl}
                notifications={notifications}
                handleClose={handleClose}
                handleDeleteNotification={handleDeleteNotification}
                handleMarkNotificationRead={handleMarkNotificationRead}
            />
        </>
    );
};
