interface BuildAuthHeadersInput {
    walletAddress: string;
    userId: string;
}

export const buildAuthHeaders = ({ walletAddress, userId }: BuildAuthHeadersInput) => {
    return {
        'Authorization': userId,
        'Wallet': walletAddress
    };
};
