import React, { useState, useEffect, useMemo } from 'react';

import CountUp from 'react-countup';
import styled from 'styled-components';

import { LoadableElement } from '../../LoadableElement';

const TextWrapper = styled.div<{ $fontSize?: number }>`
    span {
        font-weight: bold;
        font-size: ${(props) => props.$fontSize ?? 2}rem;
        margin: 0;
    }
`;

interface CountUpTextProps {
    value?: number | string;
    prefix?: string;
    fontSize?: number;
}

export const CountUpText: React.FC<CountUpTextProps> = ({
    value,
    fontSize,
    prefix = '$'
}) => {
    const [cachedValue, setCachedValue] = useState<number>();

    useEffect(() => {
        const newStart = Number(value) - cachedValue;
        setCachedValue(newStart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const endValue = useMemo(() => value, [value]);

    const decimalSeparator = useMemo(() => value?.toString()?.split('.')[1]?.length, [value]);

    return (
        <TextWrapper $fontSize={fontSize}>
            <LoadableElement loading={typeof endValue !== 'number'} width={250} height={55}>
                <CountUp
                    preserveValue
                    start={0}
                    delay={0}
                    decimals={decimalSeparator}
                    duration={0.5}
                    separator=","
                    end={endValue as number}
                    prefix={prefix}
                >
                    {({ countUpRef }) => (
                        <div>
                            <span ref={countUpRef} />
                        </div>
                    )}
                </CountUp>
            </LoadableElement>
        </TextWrapper>
    );
};
