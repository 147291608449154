import { createContext, useContext } from 'react';
import { ProviderProps } from '../@types/types';
import { db, get, ref, set } from '../firebase';
import { useUserContext } from './UserContext';

interface FirebaseContextInterface {
    fetchData: (userId: string) => Promise<any>;
    saveData: (value: string, data: any) => Promise<any>;
    deleteData: (value: string) => Promise<any>;
}

export const FirebaseContext = createContext(undefined as unknown as FirebaseContextInterface);

export const FirebaseContextProvider: React.FC<ProviderProps> = ({ children }) => {
    const { user } = useUserContext();

    const fetchData = (userId: string) => {
        return new Promise((resolve, reject) => {
            get(ref(db, `users/${userId}`))
            .then((snap) => resolve(snap.val()))
            .catch((e) => reject(e))
        });
    };

    const saveData = (value: string, data: any) => {
        return new Promise((resolve, reject) => {
            set(ref(db, `users/${user.uid}/${value}`), data)
            .then(() => resolve('saved'))
            .catch((e) => reject(e))
        });
    };

    const deleteData = (value: string) => {
        return new Promise((resolve, reject) => {
            set(ref(db, `users/${value}`), null)
            .then(() => resolve('deleted'))
            .catch((e) => reject(e))
        });
    };

    return (
        <FirebaseContext.Provider
            value={{
                saveData,
                fetchData,
                deleteData
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

export const useFirebaseContext = () => {
    const context = useContext(FirebaseContext);
    return context;
};
