import React, { useMemo } from 'react';

import { useUserContext } from '../../../context/UserContext';
import { BalanceFooterWrapper } from '../styles';
import { TransactionType, TransactionActiveInput } from '../../../@types/types';
import { useMarketAssetsContext } from '../../../context/MarketContext';

interface BalanceFooterProps {
    symbol: string;
    activeTab: TransactionType;
    activeInput: TransactionActiveInput;
}

export const BalanceFooter: React.FC<BalanceFooterProps> = ({
    symbol,
    activeTab,
    activeInput
}) => {
    const { user } = useUserContext();
    const { getAssetBySymbol } = useMarketAssetsContext();

    const currentLabel = useMemo(() => {
        switch (activeTab) {
            case TransactionType.BUY:
                return {
                    label: 'Buying power',
                    value: `$${user.balance?.toFixed(2) ?? '0.00'}`,
                }
            case TransactionType.SELL:
                const assetValue = (user.assets[symbol]?.shares ?? 0) * getAssetBySymbol(symbol)?.currentPrice;
                return {
                    label: activeInput === TransactionActiveInput.SYMBOL ? `Current ${symbol} value` : `Current ${symbol}`,
                    value: activeInput === TransactionActiveInput.SYMBOL ? `$${assetValue?.toFixed(2)}` : (user.assets[symbol]?.shares?.toFixed(4) ?? 0)
                }
        }
    }, [activeInput, activeTab, getAssetBySymbol, symbol, user.assets, user.balance]);

    return (
        <BalanceFooterWrapper>
            <span> {currentLabel.label}: </span>
            <span>{currentLabel?.value ?? 0}</span>
        </BalanceFooterWrapper>
    );
};
