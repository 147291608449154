import styled from "styled-components";
import { Link } from "react-router-dom";
import { Menu, MenuItem, Divider } from '@mui/material'
import { buildBreakpoint } from "../../../styles/common";

export const AccountMenuContainer = styled.div`
    display: block;
`;

export const UserInfoWrapper = styled.div`
    margin: 0.5rem 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
        font-weight: 500;
        font-size: 1.3rem;
        color: ${(props) => props.theme.colors.textSecondary};
        margin-bottom: 0.5rem;
    }
`;

export const StyledMenu = styled(Menu)`
    .MuiPaper-root {
        color: ${(props) => props.theme.text};
        background-color: ${(props) => props.theme.colors.surface};
        box-shadow: none;
        svg {
            fill: ${(props) => props.theme.text};
        }

        li {
            padding: 1rem;
            :hover {
                background-color: ${(props) => props.theme.colors.primaryFadedHover};
            }
        }

        :before {
            background-color: ${(props) => props.theme.colors.surface};
        }
    }
`;

export const StyledMenuItem = styled(MenuItem)`
    &&& {
        font-family: inherit;
    }
`;

export const StyledDivider = styled(Divider)`
    &&& {
        border-color: ${(props) => props.theme.colors.border};
    }
`;

export const ItemsWrapper = styled.div`
    margin: 0.5rem 0;
`;

export const LinkWrapper = styled(Link)<{ $mobileOnly?: boolean; }>`
    text-decoration: none;
    color: inherit;

    display: ${(props) => props.$mobileOnly ? 'none' : 'initial'};

    ${buildBreakpoint('mobile')} {
        ${(props) => props.$mobileOnly && 'display: block'};
    };
`;