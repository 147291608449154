import React from 'react';

import styled, { css } from 'styled-components';

import { ProviderProps } from '../../@types/types';
import { SiteLogo } from '../../components/SiteLogo';
import { buildBreakpoint } from '../../styles/common';
import { MobileMenu } from './MobileMenu';

const ContentContainer = styled.main`
    display: flex;

    ${buildBreakpoint('tablet')} {
        margin-bottom: 8rem;
    };
`;

const MainContent = styled.div`
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;

    ${buildBreakpoint('tablet')} {
        margin-left: 0;
    };
`;

const Navbar = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    height: 50px;
    padding: 16px;
    width: -webkit-fill-available;
    border-left: 1px solid ${(props) => props.theme.colors.border};
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    background-color: ${(props) => props.theme.body};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NavInnerContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    margin: 0 2rem;
    width: 100%;
    color: ${({ theme }) => theme.text};
    max-width: 1250px;
`;

const sharedButtonStyles = css`
    font-size: 1rem;
    font-weight: 500;
    font-family: inherit;
    padding: 0.5rem 2rem;
    cursor: pointer;
    border-radius: 10px;
    transition: 0.2s;
    border: none;
    color: ${({ theme }) => theme.text};

`;

const ButtonWrapper = styled.div`
    ${buildBreakpoint('mobile')} {
        display: none;
    }
`;

const LoginButton = styled.button<{ $asMobile?: boolean; }>`
    ${sharedButtonStyles};
    background: ${(props) => props.$asMobile ? props.theme.colors.border : 'transparent'};
    margin-right: ${(props) => props.$asMobile ? 0 : 1}rem;

    :hover {
        background-color: ${({ theme }) => theme.colors.border};
    }
`;

const SignupButton = styled.button`
    ${sharedButtonStyles};
    background-color: ${({ theme }) => theme.colors.primary};

    :hover {
        background-color: ${({ theme }) => theme.colors.primaryLight};
    }
`;

export const ActionButtons: React.FC<{ asMobile?: boolean }> = ({ asMobile = false }) => {
    return (
        <>
            <a href="/login">
                <LoginButton $asMobile={asMobile}>Login</LoginButton>
            </a>
            <a href="/sign-up">
                <SignupButton>Sign up</SignupButton>
            </a>
        </>
    )
}

export const LanderLayout: React.FC<ProviderProps> = ({ children }) => {
    return (
        <ContentContainer>
            <MainContent>
                <Navbar>
                    <NavInnerContainer>
                        <SiteLogo  />
                        <ButtonWrapper>
                            <ActionButtons />
                        </ButtonWrapper>
                        <MobileMenu />
                    </NavInnerContainer>
                </Navbar>
                {children}
            </MainContent>
        </ContentContainer>
    );
};
