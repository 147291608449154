import React, { useState } from 'react';

import styled from 'styled-components';
import { IconButton } from '@mui/material';

import { Icon } from '../../common/icons';
import { AssetSearchModal } from '../../AssetSearchModal';
import { buildBreakpoint } from '../../../styles/common';

const Container = styled.div`
    margin-inline: 2rem;
    position: relative;

    ${buildBreakpoint('mobile')} {
        display: none;
    };
`;

const IconWrapper = styled(IconButton)`
    width: 50px;
    height: 50px;
    &&& {
        display: flex;

        svg {
            fill: ${(props) => props.theme.text};
        }

        .MuiBadge-badge {
            font-family: inherit;
            top: -5px;
            right: -5px;
            color: ${(props) => props.theme.text};
            background-color: ${(props) => props.theme.colors.primary};
        }
    }
`;

export const Search: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <Container>
            <IconWrapper onClick={() => setOpen(!open)}>
                <Icon variant="search" />
            </IconWrapper>
            <AssetSearchModal
                open={open}
                handleClose={() => setOpen(!open)}
                handleSelectAsset={(asset) => window.location.assign(`/asset?pair=${asset.id}`)}
            />
        </Container>
    );
};
