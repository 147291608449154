import { useMemo } from 'react';
import { useUserContext } from '../context/UserContext';
import { useMarketAssetsContext } from '../context/MarketContext';

export const useUserBalance = () => {
    const { user } = useUserContext();
    const { getAssetBySymbol } = useMarketAssetsContext();

    const userBalance = useMemo(() => {
        let balance = user.balance;
        if (user?.assets) {
            Object.entries(user?.assets)
            .map(([symbol, { shares }]) => {
                const shareValue = getAssetBySymbol(symbol)?.currentPrice * shares;
                return balance += shareValue;
            });

            user?.limitPositions?.forEach((position) => {
                if (position.type === 'PURCHASE') {
                    balance += position.purchaseAsk;
                } else {
                    const limitValue = getAssetBySymbol(position.symbol)?.currentPrice * position.sellAsk;
                    balance += limitValue;
                }
            })
            return balance;
        }
    }, [user.balance, user?.assets, user?.limitPositions, getAssetBySymbol]);

    return { userBalance };
};