import axios from 'axios';
import { User } from '../@types/types';

import { API_ENDPOINT } from '../constants/api';
import { buildAuthHeaders } from '../utils/buildAuthHeaders';

type Method = 'GET' | 'PUT' | 'POST' | 'DELETE';

type Endpoint =
    '/all-assets' |
    '/sell-asset' |
    '/reset-user' |
    '/delete-user' |
    '/update-user' |
    '/purchase-asset' |
    '/update-watchlist' |
    '/open-limit-position' |
    '/cancel-limit-position' |
    '/user-details';

interface RequestInput {
    method?: Method | null;
    endpoint: Endpoint;
    postData?: any | null;
}

interface AuthRequestInput extends RequestInput {
    user: User;
}

export async function request<T>({ method = 'GET', endpoint, postData }: RequestInput): Promise<T> {
    return await new Promise((resolve, reject) => {
        return axios({
            method,
            url: `${API_ENDPOINT}${endpoint}`,
            data: postData
        })
            .then(({ data }) => resolve(data as T))
            .catch((e) => reject(e))
    })
};

export async function authenticatedRequest<T>({ method = 'POST', endpoint, postData, user }: AuthRequestInput): Promise<T> {
    return await new Promise((resolve, reject) => {
        return axios({
            method,
            url: `${API_ENDPOINT}${endpoint}`,
            data: postData,
            headers: buildAuthHeaders({ walletAddress: user.walletAddress, userId: user.id })
        })
        .then(({ data }) => {
            if ('error' in data) {
                return reject({ error: data.error });
            }
            return resolve(data as T);
        })
        .catch((error) => reject({ error }))
    })
};

export const useRequest = () => {
    return {
        request,
        authenticatedRequest
    }
}