import {
    MOBILE_BREAKPOINT,
    TABLET_BREAKPOINT,
    LAPTOP_BREAKPOINT,
    PRIMARY_RGB,
    SECONDARY_RGB,
    THEME_GREEN_RGB,
    THEME_RED_RGB,
    REDUCED_WIDTH_BREAKPOINT,
    CONTENT_MAX_WIDTH,
    GRAY_RGB
} from "./variables";

export type BitSimTheme = typeof lightTheme;

const colors = {
    primary: `rgba(${PRIMARY_RGB}, 1)`,
    primaryLight: `rgba(${PRIMARY_RGB}, 0.6)`,
    primaryFaded: `rgba(${PRIMARY_RGB}, 0.1)`,
    primaryFadedHover: `rgba(${PRIMARY_RGB}, 0.05)`,
    secondary: `rgb(${SECONDARY_RGB})`,
    secondaryHover: `rgba(${SECONDARY_RGB}, 0.8)`,
    gray: `rgb(${GRAY_RGB})`,
    border: `rgba(${GRAY_RGB}, 0.3)`,
    red: `rgba(${THEME_RED_RGB}, 1)`,
    green: `rgba(${THEME_GREEN_RGB}, 1)`,
    surface: '#f5f5f5',
    textSecondary: 'rgba(0,0,0,0.8)',
};

const breakpoints = {
    mobile: `${MOBILE_BREAKPOINT}px`,
    tablet: `${TABLET_BREAKPOINT}px`,
    laptop: `${LAPTOP_BREAKPOINT}px`
}

const widths = {
    contentMaxWidth: `${CONTENT_MAX_WIDTH}px`,
    contentReducedWidth: `${REDUCED_WIDTH_BREAKPOINT}px`
}

const baseTheme = {
    colors,
    widths,
    breakpoints
}

export const lightTheme = {
    ...baseTheme,
    colors,
    body: 'white',
    text: '#1a1a1a'
};

export const darkTheme = {
    ...baseTheme,
    colors: {
        ...colors,
        surface: '#1a1a1a',
        textSecondary: 'rgba(255,255,255,0.6)'
    },
    text: '#f7f7f7',
    body: '#0f0f0f'
};