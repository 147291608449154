import React from 'react';
import { Navigate } from 'react-router-dom';

import { useUserContext } from '../context/UserContext';

export const AuthRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { isLoggedIn, initialized } = useUserContext();

    if (!initialized) {
        return null;
    }

    if (!isLoggedIn) {
        return <Navigate to="/login" />;
    }

    return <>{children}</>;
}