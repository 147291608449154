import { createContext, useEffect, useState, useMemo, useContext } from 'react';
import { onValue, ref, set } from 'firebase/database';
import moment from 'moment';

import { User, ProviderProps, TradeHistoryInterface, BalanceHistory } from '../@types/types';
import { auth, db, deleteUser, onAuthStateChanged, signInWithEmailAndPassword, updateEmail, updatePassword } from '../firebase';
import { authenticatedRequest, request } from '../hooks/useRequest';

interface UserContextProps {
    user: User;
    initialized: boolean;
    isLoggedIn: boolean;
    transactionHistorySnapshot: TradeHistoryInterface[];

    filteredBalanceHistory: (inRange: string | number) => BalanceHistory[];
    addRemoveAssetFromWatchlist: (symbol: string) => void;
    setUserData: React.Dispatch<React.SetStateAction<User>>;

    handleDeleteNotification: (id: string) => void;
    handleMarkNotificationRead: (id: string) => void;
    handleUpdateUserSettings: (key: string, value: boolean) => void;
    handleUpdatePassword: (current: string, password: string) => Promise<any>;
    handleUpdateEmail: (email: string, password: string) => Promise<any>;
    handleVerifyPassword: (password: string) => Promise<any>;
    handleResetAccount: (startingBalance: number) => Promise<any>;
    handleDeleteUser: () => Promise<any>;
    handleUpdateUserAttribute: (key: string, value: string) => void;

    signOut: () => void;
};

export const fetchUserDetails = async (walletAddress: string | undefined): Promise<User> => {
    if (!walletAddress) return;

    return request<User>({ method: 'POST', endpoint: '/user-details', postData: { walletAddress } })
    .then((data) => data)
    .catch((e) => e)
};

export const UserContext = createContext(undefined as unknown as UserContextProps);

export const UserContextProvider: React.FC<ProviderProps> = ({ children }) => {
    const [userData, setUserData] = useState<User | null>(null);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    // useTickFactory(userData, setUserData);

    const signOut = () => {
        auth.signOut()
        .then(() => window.location.replace('/login'));
    };

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setUserData(user);
            if (user) {
                onValue(ref(db, `users/${user.uid}`), (snap) => {
                    if (!snap.val()) {
                        return;
                    }

                    setUserData((prev) => ({
                        ...prev,
                        ...snap.val()
                    }));

                    const onboardingKey = localStorage.getItem('onboarding');

                    if (!onboardingKey) {
                        setIsLoggedIn(true);
                        setInitialized(true);
                    }
                });
            } else {
                setIsLoggedIn(false);
                setInitialized(true);
            }
        })
    }, []);

    useEffect(() => {
        if (!userData?.walletAddress) return;

        fetchUserDetails(userData.walletAddress)
        .then((data) => {
            const history = ([...data.limitPositions, ...data.history] as unknown as TradeHistoryInterface[])
                .sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
                .map((item) => ({
                    ...item,
                    type: 'limitAsk' in item ? 'WORKING' : item.type
                }));
            
            setUserData((prev) => ({
                ...prev,
                ...data,
                history
            }));
        });
        //eslint-disable-next-line
    }, [userData?.walletAddress]);
    
    const user = useMemo(() => {
        return {
            ...userData,
            id: userData?.uid,
            name: userData?.displayName ?? userData?.name,
        }
    }, [userData]);

    const transactionHistorySnapshot = useMemo(() => {
        if (user?.history?.length >= 5) {
            return user?.history?.sort((a,b) => a.timestamp < b.timestamp ? 1 : -1)?.slice(0,5);
        }
        return user?.history;
    }, [user?.history]);

    const filteredBalanceHistory = (inRange: string | number) => {
        if (inRange === 'max') {
            return user?.balanceHistory?.sort((a,b) => a.timestamp > b.timestamp ? 1 : -1);
        }

        const range = inRange && inRange !== 1 ? moment().subtract(inRange, 'days').valueOf() : moment().valueOf(); 
        const filteredHistory = user?.balanceHistory?.filter((item) => {
            return moment(item.timestamp).isSameOrAfter(range, 'days');
        });
    
        return filteredHistory;
    }

    const addRemoveAssetFromWatchlist = (symbol: string) => {
        const newWatchlist = [...user.watchlist];

        if (newWatchlist.indexOf(symbol) !== -1) {
            newWatchlist.splice(newWatchlist.indexOf(symbol), 1);
        } else {
            newWatchlist.push(symbol);
        }

        setUserData((prev) => ({
            ...prev,
            watchlist: newWatchlist
        }));

        authenticatedRequest({ method: 'PUT', endpoint: '/update-watchlist', user, postData: {
            walletAddress: user?.walletAddress,
            watchlist: newWatchlist
        }})
        .catch((error) => error);
    };

    const handleDeleteNotification = (id: string) => {
        const filteredNotifications = user?.notifications?.filter((item) => item.id !== id);

        setUserData((prev) => ({
            ...prev,
            notifications: filteredNotifications
        }));

        authenticatedRequest({ method: 'PUT', endpoint: '/update-user', user, postData: {
            key: 'notifications',
            walletAddress: user?.walletAddress,
            updates: filteredNotifications
        }})
        .catch((error) => error);
    };

    const handleUpdateUserSettings = (key: string, value: boolean) => {
        const newSettings = {...user?.settings, [key]: value};
        setUserData((prev) => ({
            ...prev,
            settings: newSettings
        }));

        authenticatedRequest({ method: 'PUT', endpoint: '/update-user', user, postData: {
            key: 'settings',
            walletAddress: user?.walletAddress,
            updates: newSettings
        }})
        .catch((error) => error);
    };

    const handleUpdateUserAttribute = (key: string, value: string) => {
        authenticatedRequest({ method: 'PUT', endpoint: '/update-user', user, postData: {
            key,
            walletAddress: user?.walletAddress,
            updates: value
        }})
        .catch((error) => error);
    };

    const handleUpdatePassword = (current: string, password: string) => {
        return new Promise((resolve, reject) => {
            signInWithEmailAndPassword(auth, user.email, current)
            .then(() => {
                updatePassword(auth.currentUser, password)
                .then(() => resolve('updated'))
                .catch((e) => reject(e))
            })
            .catch((e) => reject(e))
        });
    };

    const handleUpdateEmail = (newEmail: string, password: string) => {
        return new Promise((resolve, reject) => {
            signInWithEmailAndPassword(auth, user.email, password)
            .then(() => {
                updateEmail(auth.currentUser, newEmail)
                .then(() => resolve('updated'))
                .catch((e) => reject(e))
            })
            .catch((e) => reject(e))
        });
    };

    const handleVerifyPassword = (password: string) => {
        return new Promise((resolve, reject) => {
            signInWithEmailAndPassword(auth, user.email, password)
            .then(() => resolve('valid'))
            .catch((e) => reject(e));
        });
    };

    const handleResetAccount = (startingBalance: number) => {
        return authenticatedRequest({ endpoint: '/reset-user', user, postData: {
            startingBalance,
            walletAddress: user.walletAddress,
            username: user?.username,
            resetAccountData: moment().valueOf()
        }})
        .then((data) => data)
        .catch((error) => error);
    };

    const handleDeleteUser = () => {
        return authenticatedRequest({ method: 'DELETE', endpoint: '/delete-user', user, postData: {
            walletAddress: user.walletAddress
        }})
        .then(() => {
            set(ref(db, `users/${user.id}`), null)
            .then(() => {
                deleteUser(auth.currentUser)
                .then(() => 'deleted')
                .catch((error) => error)
            })
            .catch((error) => error)
        });
    };

    const handleMarkNotificationRead = (id: string) => {
        const filteredNotifications = user?.notifications?.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    read: true
                }
            } else {
                return { ...item }
            }
        });

        setUserData((prev) => ({
            ...prev,
            notifications: filteredNotifications
        }));

        authenticatedRequest({ method: 'PUT', endpoint: '/update-user', user, postData: {
            key: 'notifications',
            walletAddress: user?.walletAddress,
            updates: filteredNotifications
        }})
        .catch((error) => error)
    }

    return (
        <UserContext.Provider
            value={{
                user,
                isLoggedIn,
                initialized,
                transactionHistorySnapshot,

                setUserData,
                addRemoveAssetFromWatchlist,
                filteredBalanceHistory,

                handleUpdateUserSettings,
                handleDeleteNotification,
                handleMarkNotificationRead,
                handleUpdatePassword,
                handleUpdateEmail,
                handleUpdateUserAttribute,
                handleVerifyPassword,
                handleResetAccount,
                handleDeleteUser,

                signOut
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = (): UserContextProps => {
    const context = useContext(UserContext);
    return context;
}
