

export const formatWalletAddress = (walletAddress?: string, trim = true): string | null => {
    if (!walletAddress) return null;

    const formattedWalletAddress = trim || walletAddress.length > 50 ?
        walletAddress.slice(0,6) + '...' + walletAddress.slice(38, walletAddress.length) : walletAddress;

    return formattedWalletAddress;
};
