import React from "react";

import styled from "styled-components";

const TextContainer = styled.div<{ $value?: number }>`
    color: ${(props) => props.theme.colors[props.$value === 0 ? 'gray' : props.$value > 0 ? 'green' : 'red']};
`;

type ElementType = 'span' | 'strong' | 'small';

interface ColoredTextProps {
    value: number;
    text: string;
    element?: ElementType;
    [x:string]: any;
}

export const ColoredText: React.FC<ColoredTextProps> = ({ value, text, element = 'span', ...rest }) => {
    const TextElement = element ?? 'span';
    return (
        <TextContainer $value={value}>
            <TextElement {...rest}>{text}</TextElement>
        </TextContainer>
    );
};
